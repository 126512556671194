import { BusInfoResponse } from "./bus-info.interface";
import { FeedInfoResponse } from "./feed-info.interface";

export interface GuestResponse {
    id: string;
    name: boolean;
    firstSurName: string;
    lastSurName: string;
    attendance: AttendanceType;
    type: GuestType;
    canAddCompanion: boolean;
    familyId: string;
    busInfo: BusInfoResponse;
    feedInfo: FeedInfoResponse;
    companion: CompanionResponse;
}

export interface SearchGuestRequest {
  searchname: string;
  searchfirstsurname: string;
  searchlastsurname: string;
  searchCode: string
}

export interface CreateCompanionRequest {
  name: string;
  firstSurName: string;
  lastSurName: string;
}

export interface CompanionResponse {
  id: string;
  name: string;
  firstSurName: string;
  lastSurName: string;
}

export enum GuestType {
  Adult = 0, 
  Kid = 1,
  Baby = 2
}

export enum AttendanceType {
  Pending, 
  Yes,
  No
}