import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { BusInfoCityResponse } from "src/app/models/bus-info/bus-info-city.interface";
import { BusInfoTimeResponse } from "src/app/models/bus-info/bus-info-time.interface";

@Injectable({
    providedIn: 'root',
  })
  export class BusInfoService {
    private _environment;
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment) {
      this._environment = environment
    }
    
   getCities(): Observable<BusInfoCityResponse[]> {
      return this.httpClient
        .get<BusInfoCityResponse[]>(
          this._environment.apiUrl + '/config/bus-info/cities'
        )
        .pipe(retry(1), catchError(this.processError));
    }

    getTimesBack(cityId: string): Observable<BusInfoTimeResponse[]> {
      return this.httpClient
        .get<BusInfoTimeResponse[]>(
          this._environment.apiUrl + '/config/bus-info/times/back/' + cityId
        )
        .pipe(retry(1), catchError(this.processError));
    }

    putCity(tripId: string, busInfoCityId: string): Observable<any> {
        return this.httpClient
          .put<any>(
            this._environment.apiUrl + "/bus-info/" + tripId + '/city/' + busInfoCityId,
            null
          )
          .pipe(retry(1), catchError(this.processError));
    }

    putTime(tripId: string, busInfoTimeId: string): Observable<any> {
      return this.httpClient
        .put<any>(
          this._environment.apiUrl + "/bus-info/" + tripId + '/time/' + busInfoTimeId,
          null
        )
        .pipe(retry(1), catchError(this.processError));
    }

    putBusInfoIsNeeded(id: string, isNeeded: boolean): Observable<any> {
        return this.httpClient
        .put<any>(
          this._environment.apiUrl + '/guest/' + id + '/bus-info/' + isNeeded,
          null
        )
        .pipe(retry(1), catchError(this.processError));
    }

    putBusTripIsNeeded(id: string, isNeeded: boolean): Observable<any> {
      return this.httpClient
      .put<any>(
        this._environment.apiUrl + '/bus-info/' + id + '/isNeeded/' + isNeeded,
        null
      )
      .pipe(retry(1), catchError(this.processError));
  }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }