<div class="modal" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{title}}</h2>
            </div>
            <form>
                <div class="text-center" *ngIf="this.feedInfo">
                    <p>{{body}}</p>
                    <button class="{{ this.feedInfo[0] ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setCeliac(!this.feedInfo[0])" type="button">Celíaco</button>
                    <button class="{{ this.feedInfo[1] ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setVegetarian(!this.feedInfo[1])" type="button">Vegetariano</button>
                    <button class="{{ this.feedInfo[2] ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setVegan(!this.feedInfo[2])" type="button">Vegano</button>
                    <button class="{{ this.feedInfo[3] ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setAllergic(!this.feedInfo[3])" type="button">Alérgico</button>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="confirmationOK()">{{submitButton}}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>