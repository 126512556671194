import { Component, HostListener, OnInit } from '@angular/core';
import { ImageResponse, ImagesPageResponse } from 'src/app/models/gallery/image.interface';
import { GalleryService } from 'src/app/services/gallery/gallery.service';
import Lity from 'lity';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  public imagesPage: ImagesPageResponse = { images: [], total: 0 };
  public gallery: ImageResponse[] = [];
  public pageNumber: number = 1;
  public pageSize: number = 4;
  public leftMoreImages = true;

  constructor(private _galleryService: GalleryService) { }

  ngOnInit() { }

  async ngAfterViewInit() {
    await this.getImages();
  }

  async getImages(){
    this._galleryService.getImages(this.pageSize, this.pageNumber).subscribe((data) =>{
      this.imagesPage = data;
      this.setImages();
    });
  }

  setImageSelected(base64string : string){
    Lity(base64string);
  }

  setImages(){
    this.imagesPage.images.forEach(el => {
      var image: ImageResponse = { data:  "data:image/png;base64," + el.data, height: el.height, width: el.width  }
      this.gallery.push(image);
    });
    this.leftMoreImages = this.imagesPage.total > this.pageNumber * this.pageSize;
  }

  async onScroll() {
    if(this.leftMoreImages)
    {
      this.pageNumber ++;
      await this.getImages();
    }
  }
}
