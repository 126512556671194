import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Salva & Teresa 2024';
  public isAdminMode = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else (evt instanceof NavigationEnd) 
      {
        this.checkAdminMode(evt.url);
      }
      window.scrollTo(0, 0);
    });
  }

  private checkAdminMode(url: string): void { 
    this.isAdminMode = url.startsWith('/a');
  }
}
