<mat-nav-list>
  <mat-divider></mat-divider>
  <h2 matSubheader class="mt-2">ADMIN</h2>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/a/dashboard">
    <mat-icon>dashboard</mat-icon> Dashboard
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/a/guests-info">
    <mat-icon>dashboard</mat-icon> Invitados
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/a/guests-messages">
    <mat-icon>dashboard</mat-icon> Mensajes
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/a/guests-songs">
    <mat-icon>dashboard</mat-icon> Canciones
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/a/posts">
    <mat-icon>dashboard</mat-icon> Posts
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/a/configuration">
    <mat-icon>settings</mat-icon> Configuración
  </a>

</mat-nav-list>
