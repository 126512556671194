import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { CodeService } from "./code.service";

@Injectable({
  providedIn: 'root'
})
export class CodeOrAuthGuard {
  public isPassedIn = false;

  constructor(private _codeService: CodeService, private _authService: AuthService, private router: Router){}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      var isPassedIn = this._codeService.isPassedIn;

      if(isPassedIn || this._authService.loggedIn() ){
        return true;
      } else if(isPassedIn) {
        this.router.navigate(['/p/rsvp']);
        return false;
      }

      if(next.queryParamMap.get('code')){
        let code = next.queryParamMap.get('code');
        this._codeService.checkCode(code).then((res: any) =>{
          if(res) {
            this._codeService.setValidCode(code);
            return true;
          } else {
            return false; 
          }
        });
      }
      else if(this._codeService.getValidCode() != null){
        let code = this._codeService.getValidCode();
        this._codeService.checkCode(code).then((res: any) =>{
          if(res){
            this._codeService.setValidCode(code);
            return true;
          } else {
            return false; 
          }
        });
      }
      else{
        this.router.navigate(['/p/code']);
        return false;
      }
  }
}
