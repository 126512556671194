import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { BusTripType, DashboardAttendanceGuestResponse, DashboardBusInfoResponse, DashboardFeedInfoGuestResponse } from 'src/app/models/admin/dashboard/dashboard.interface';
import { DashboardService } from 'src/app/services/admin/dashboard/dashboard.service';

@Component({
  selector: 'admin-app-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements AfterViewInit  {
  @ViewChild('chartAttendanceGuests') private chartAttendanceGuestsRef: ElementRef;
  private chartAttendanceGuests: any;
  @ViewChild('chartFeedInfoGuests') private chartFeedInfoGuestsRef: ElementRef;
  private chartFeedInfoGuests: any;
  attendanceGuestsData: DashboardAttendanceGuestResponse;
  feedInfoGuestsData: DashboardFeedInfoGuestResponse;
  public dashboardBusInfoData: DashboardBusInfoResponse;
  busTripTypeEnum = BusTripType;
  public totalAdults: number = 0;
  public totalKids: number = 0;
  public totalBabies: number = 0;  
  constructor(private _dashboardService: DashboardService) { }

  ngAfterViewInit() {
    this.getData();
  }

  getData(){
    this._dashboardService.getDashboardAttendanceGuests().subscribe(response => {
      this.attendanceGuestsData = response;
      this.createAttendanceChart();
      this.totalAdults = this.getDataByGuestType(0);
      this.totalKids = this.getDataByGuestType(1);
      this.totalBabies = this.getDataByGuestType(2);
    }, error => {
      console.error('Error al obtener la lista de posts:', error);
    });

    this._dashboardService.getDashboardFeedInfoGuests().subscribe(response => {
      this.feedInfoGuestsData = response;
      this.createFeedInfoChart();
    }, error => {
      console.error('Error al obtener la lista de posts:', error);
    });


    this._dashboardService.getDashboardBusInfo().subscribe(response => {
      this.dashboardBusInfoData = response;
      const groupedData = this.dashboardBusInfoData.trips.reduce((acc, trip) => {
        if (!acc[trip.type]) {
          acc[trip.type] = { type: trip.type, citiesTimes: [] };
        }
        acc[trip.type].citiesTimes.push(...trip.citiesTimes);
        return acc;
      }, {});
  
      this.dashboardBusInfoData.trips = Object.values(groupedData);
    }, error => {
      console.error('Error al obtener la lista de posts:', error);
    });
  }

  createAttendanceChart() {
    if (this.chartAttendanceGuestsRef && this.chartAttendanceGuestsRef.nativeElement && this.attendanceGuestsData && this.attendanceGuestsData.guestAttendances) {

      const ctx = this.chartAttendanceGuestsRef.nativeElement.getContext('2d');

      this.chartAttendanceGuests = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Adultos', 'Niños', 'Bebés'],
          datasets: [
            {
              label: 'Asistirán',
              data: this.getAttendanceDataByAttendanceStatus(1),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Pendientes',
              data: this.getAttendanceDataByAttendanceStatus(0),
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1,
            },
            {
              label: 'No Asistirán',
              data: this.getAttendanceDataByAttendanceStatus(2),
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
          ],
        },
      });
    }
  }

  createFeedInfoChart(){
    if (this.chartFeedInfoGuestsRef && this.chartFeedInfoGuestsRef.nativeElement && this.feedInfoGuestsData) {

      const ctx = this.chartFeedInfoGuestsRef.nativeElement.getContext('2d');

      const modifiedFeedInfoGuestsData = JSON.parse(JSON.stringify(this.feedInfoGuestsData));

      Object.keys(modifiedFeedInfoGuestsData.adults).forEach((key) => {
        switch (key) {
          case 'allergics':
            modifiedFeedInfoGuestsData.adults.alergicos = this.feedInfoGuestsData.adults.allergics;
            delete modifiedFeedInfoGuestsData.adults.allergics;
            break;
          case 'celiacs':
            modifiedFeedInfoGuestsData.adults.celíacos = this.feedInfoGuestsData.adults.celiacs;
            delete modifiedFeedInfoGuestsData.adults.celiacs;
            break;
          case 'vegetarians':
            modifiedFeedInfoGuestsData.adults.vegetarianos = this.feedInfoGuestsData.adults.vegetarians;
            delete modifiedFeedInfoGuestsData.adults.vegetarians;
            break;
          case 'vegans':
            modifiedFeedInfoGuestsData.adults.veganos = this.feedInfoGuestsData.adults.vegans;
            delete modifiedFeedInfoGuestsData.adults.vegans;
            break;
        }
      });

      Object.keys(modifiedFeedInfoGuestsData.kids).forEach((key) => {
        switch (key) {
          case 'allergics':
            modifiedFeedInfoGuestsData.kids.alergicos = this.feedInfoGuestsData.kids.allergics;
            delete modifiedFeedInfoGuestsData.kids.allergics;
            break;
          case 'celiacs':
            modifiedFeedInfoGuestsData.kids.celíacos = this.feedInfoGuestsData.kids.celiacs;
            delete modifiedFeedInfoGuestsData.kids.celiacs;
            break;
          case 'vegetarians':
            modifiedFeedInfoGuestsData.kids.vegetarianos = this.feedInfoGuestsData.kids.vegetarians;
            delete modifiedFeedInfoGuestsData.kids.vegetarians;
            break;
          case 'vegans':
            modifiedFeedInfoGuestsData.kids.veganos = this.feedInfoGuestsData.kids.vegans;
            delete modifiedFeedInfoGuestsData.kids.vegans;
            break;
        }
      });

      this.chartFeedInfoGuests = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: [
            {
              label: 'Adultos',
              data: modifiedFeedInfoGuestsData.adults,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Niños',
              data: modifiedFeedInfoGuestsData.kids,
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1,
            }
          ],
        },
      });
    }
  }


  getAttendanceDataByAttendanceStatus(status: number): number[] {
    return this.attendanceGuestsData.guestAttendances
      .filter(item => item.type === status)
      .map(item => item.attendance.map(entry => entry.key))
      .reduce((acc, curr) => acc.concat(curr), []);
  }

  getDataByGuestType(guestType: number): number {
    return this.attendanceGuestsData.guestAttendances
      .map(item => item.attendance.filter(i => i.value == guestType).map(entry => entry.key))
      .reduce((acc, curr) => acc.concat(curr), [])
      .reduce((sum, value) => sum + value, 0);
  }

  getBackgroundColor(index: number): string {
    const colors = ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 205, 86, 0.2)'];
    return colors[index % colors.length];
  }
  
  getBorderColor(index: number): string {
    const colors = ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 205, 86, 1)'];
    return colors[index % colors.length];
  }
}
