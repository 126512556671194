import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAppComponent } from './pages/admin/admin-app.component';
import { AdminDashboardComponent } from './pages/admin/dashboard/admin-dashboard.component';
import { AdminGuestsComponent } from './pages/admin/guests-info/admin-guests.component';
import { AdminGuestsMessagesComponent } from './pages/admin/guests-messages/guests-messages.component';
import { AdminGuestsSongsComponent } from './pages/admin/guests-songs/guests-songs.component';
import { AdminHomeComponent } from './pages/admin/home/admin-home.component';
import { AdminLoginComponent } from './pages/admin/login/admin-login.component';
import { AdminPostsComponent } from './pages/admin/posts-info/admin-posts.component';
import { CodeComponent } from './pages/public/code/code.component';
import { GalleryComponent } from './pages/public/gallery/gallery.component';
import { LandingPageComponent } from './pages/public/landing-page/landing-page.component';
import { PostDetailComponent } from './pages/public/posts/postdetail/post-detail.component';
import { PostsComponent } from './pages/public/posts/posts.component';
import { PublicAppComponent } from './pages/public/public-app.component';
import { RsvpDataComponent } from './pages/public/rsvp-data/rsvp-data.component';
import { RsvpComponent } from './pages/public/rsvp/rsvp.component';
import { ScheduleComponent } from './pages/public/schedule/schedule.component';
import { AdminAuthGuard } from './services/auth/admin-authguard.service';
import { CodeOrAuthGuard } from './services/auth/code-or-authguard.service';
import { UserAuthGuard } from './services/auth/user-authguard.service';

const routes: Routes = [
  { path: '', redirectTo: 'p/home', pathMatch: 'full' },

  { path: 'p', component: PublicAppComponent,
  children: [
    { path: 'home', component: LandingPageComponent , canActivate: [CodeOrAuthGuard] },
    { path: 'schedule', component: ScheduleComponent , canActivate: [CodeOrAuthGuard] },
    { path: 'posts', component: PostsComponent , canActivate: [CodeOrAuthGuard] },
      { path: 'posts/:postId', component: PostDetailComponent , canActivate: [CodeOrAuthGuard] },
    { path: 'gallery', component: GalleryComponent , canActivate: [CodeOrAuthGuard] },
    { path: 'rsvp', component: RsvpComponent , canActivate: [CodeOrAuthGuard] },
    { path: 'rsvp-data', component: RsvpDataComponent , canActivate: [UserAuthGuard] },
    { path: 'code', component: CodeComponent },
  ]},

  { path: 'a', component: AdminAppComponent,
  children: [
    { path: 'login', component: AdminLoginComponent },
    { path: 'home', component: AdminHomeComponent , canActivate: [AdminAuthGuard] },
    { path: 'dashboard', component: AdminDashboardComponent , canActivate: [AdminAuthGuard] },
    { path: 'guests-info', component: AdminGuestsComponent , canActivate: [AdminAuthGuard] },
    { path: 'guests-messages', component: AdminGuestsMessagesComponent , canActivate: [AdminAuthGuard] },
    { path: 'guests-songs', component: AdminGuestsSongsComponent , canActivate: [AdminAuthGuard] },
    { path: 'posts', component: AdminPostsComponent , canActivate: [AdminAuthGuard] }
  ]}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CodeOrAuthGuard, UserAuthGuard, AdminAuthGuard],
})
export class AppRoutingModule {}
