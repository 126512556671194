import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-app-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.scss']
})
export class AdminSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
