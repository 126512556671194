import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CreateSongRequest, SongResponse, SongsPageResponse } from 'src/app/models/song/song.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SongService } from 'src/app/services/songs/song.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  public songs: SongResponse[];
  public sendSongForm = false;
  public song : CreateSongRequest = { artist: "", title: "" };
  public allSongs: SongsPageResponse;
  public othersSongs: SongsPageResponse;
  public songsPageNumber = 1;
  public songsPageSize = 5;

  constructor(private _songService: SongService, public _authService: AuthService) { }

  async ngOnInit() {
    if(this._authService.loggedIn()){
      await this.getAllSongs(this.songsPageNumber);
    }
  }

  getSongs(){
    this._songService.getSongs().pipe(take(1)).subscribe(data => {
      this.songs = data;
    });
  }

  async getAllSongs(pageNumber: number){
    this.songsPageNumber = pageNumber;
    this._songService.getAllSongs(pageNumber, this.songsPageSize).pipe(take(1)).subscribe(data => {
      this.allSongs = data;
    });
  }

  async getOthersSongs(pageNumber: number){
    this.songsPageNumber = pageNumber;
    this._songService.getOthersSongs(pageNumber, this.songsPageSize).pipe(take(1)).subscribe(data => {
      this.othersSongs = data;
    });
  }

  postSong(){
    this._songService.postSong(this.song).pipe(take(1)).subscribe(data => {
      this.songsPageNumber = 1;
      this.getAllSongs(this.songsPageNumber);
      this.song = { artist: "", title: "" }
    },(error: any) => {
      
    });
  }

  deleteSong(id: string){
    this._songService.deleteSong(id).pipe(take(1)).subscribe(data => {
      this.songsPageNumber = 1;
      this.getAllSongs(this.songsPageNumber);
    });
  }
}
