<section class="gla_section">
    
    <div class="container text-center">
        <p><img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt=""></p>
        <h1>{{ 'schedule' | transloco }}</h1>
        <br>
        <br>
        <div class="row justify-content-center">
            <div class="col-md-4 text-center">
                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345.79 99.9"><defs><style>.cls-1,.cls-2,.cls-3{font-family: themify}.cls-1,.cls-2{opacity:0.6;}.cls-1{font-size:24.91px;fill:#17321b;letter-spacing:0.3em;}.cls-2{fill:#19321d;stroke:#132517;stroke-width:0.44px;}.cls-2,.cls-3{stroke-miterlimit:10;}.cls-3{opacity:0.4;font-size:50.28px;fill:#44692a;stroke:#44692a;stroke-width:1.78px;letter-spacing:-0.05em;}</style></defs><title>Sin título-1</title><g id="FECHA"><text id="JUNY" class="cls-1" transform="translate(11.11 55.51)">JUNY</text><line class="cls-2" x1="1.2" y1="21.97" x2="109.12" y2="21.97"/><text class="cls-3" transform="translate(120.47 73.81) scale(2.01 1.74)">22</text><line class="cls-2" y1="68.04" x2="109.12" y2="68.04"/><text id="_2024" data-name="2024" class="cls-1" transform="translate(253.42 55.51)">2024</text><line class="cls-2" x1="237.86" y1="21.97" x2="345.79" y2="21.97"/><line class="cls-2" x1="236.67" y1="68.04" x2="345.79" y2="68.04"/></g></svg>
            </div>
        </div>
        <br>
        <h2 [innerHTML]="'wedding-day' | transloco | markdown" style="font:500 30px perfectbeloved;"></h2>
        <br>
        <h2 [innerHTML]="'wedding-celebration' | transloco | markdown" style="font:500 30px perfectbeloved;"></h2>
        <br>
        <br>
        <!--<h4 class="text-secondary" [innerHTML]="'wedding-ceremony' | transloco | markdown"></h4>
        <br>
        <h4 class="text-secondary" [innerHTML]="'wedding-celebration' | transloco | markdown"></h4>
        <br>
        <h4 class="text-secondary"  [innerHTML]="'wedding-disco' | transloco | markdown"></h4>-->
        <!-- boxes -->
        <div class="row">

            <!-- item -->
            <div class="col-md-4 col-sm-6">
                <span class="gla_news_block">
                    <span class="gla_news_img">
                        <span class="gla_over" style="background-image:url(assets/images/ermita-santa-anna-albal.jpg);">
                            <span class="center mt-4">
                                <a class="btn button btn-lg" href="https://maps.app.goo.gl/8bmCveuk373X6nNc9" target="_blank" role="button">{{ 'wedding-ceremony-place-link' | transloco }}</a>
                            </span>
                        </span>    
                    </span>
                    <span class="gla_news_title" [innerHTML]="'wedding-ceremony-place-title' | transloco | markdown"></span>
                    <p class="text-justify" [innerHTML]="'wedding-ceremony-place' | transloco | markdown"></p>
                </span>
            </div> 

            <!-- item -->
            <div class="col-md-4 col-sm-6">
                <span class="gla_news_block">
                    <span class="gla_news_img">
                        <span class="gla_over" style="background-image:url(assets/images/hort-montesinos.jpg);">
                            <span class="center mt-4">
                                <a class="btn button btn-lg" href="https://maps.app.goo.gl/dfC5rY53YavGGGMK6" target="_blank" role="button">{{ 'wedding-celebration-place-link' | transloco }}</a>
                            </span>
                        </span>  
                    </span>
                    <span class="gla_news_title" [innerHTML]="'wedding-celebration-place-title' | transloco | markdown"></span>
                        <p class="text-justify" [innerHTML]="'wedding-celebration-place' | transloco | markdown"></p>
                </span>
            </div> 

            <!-- item -->
            <div class="col-md-4 col-sm-6">
                <span class="gla_news_block">
                    <span class="gla_news_img">
                        <span class="gla_over" style="background-image:url(assets/images/festa-hort-montesinos.jpg);"></span>  
                    </span>
                    <span class="gla_news_title" [innerHTML]="'wedding-disco-place-title' | transloco | markdown"></span>
                    <p class="text-justify" [innerHTML]="'wedding-disco-place' | transloco | markdown"></p>
                    
                    <div class="col-12 text-center">
                        <p *ngIf="!this._authService.loggedIn()" class="alert alert-warning center mx-2">{{ 'schedule-add-songs-label' | transloco }}</p>
                        <button *ngIf="!this._authService.loggedIn()" class="btn my-data btn-secondary my-4" [routerLink]="['/p/rsvp']">
                            {{ 'rsvp' | transloco }}
                        </button>
                    </div>

                    <!-- SONGS SECTION -->  
                    <div *ngIf="this.allSongs != null" class="col-12 mt-4 text-center">
                        <h3 class="text-secondary" style="margin-bottom: 10px; margin-top: 20px">{{ 'send-song-title-label' | transloco }}</h3>
                            <div class="py-1 m-4">
                            <!-- ALL SONGS LIST -->
                            <div class="col-12 py-4" *ngIf="this.allSongs.songs.length > 0">
                                <a [ngClass]="{'button-visibility-none': !(this.songsPageNumber > 1) }" class="ti ti-angle-up mx-0" (click)="this.getAllSongs(this.songsPageNumber - 1)"></a>
                                <div class="bg-secondary-card px-4">
                                    <span *ngFor="let song of this.allSongs.songs;" class="py-4">
                                        <div class="mt-3 mb-3 px-4 col-11 row">
                                            <span class="col-10 my-auto">
                                                <p [innerText]="song.artist" class="text-left my-auto px-0" style="font-weight:600"></p>
                                                <p [innerText]="song.title" class="text-left my-auto px-0" style="font-style: italic; font-weight: 100;"></p>
                                            </span>
                                            <span *ngIf="song.id != null" class="text-center col-1 my-auto">
                                                <button class="btn my-data btn-sm btn-remove ti ti-trash" (click)="this.deleteSong(song.id)"></button>
                                            </span>
                                        </div>
                                    </span>
                                    <span *ngIf="this.allSongs.songs.length < this.songsPageSize">
                                        <span *ngFor="let _ of [].constructor(this.songsPageSize - this.allSongs.songs.length);" class="py-4">
                                            <div class="mt-3 mb-3 row">
                                                <span class="text-center col-12 my-auto px-0">
                                                    <br>
                                                    <br>
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                                <a [ngClass]="{'button-visibility-none': !(this.allSongs.total > this.songsPageNumber * this.songsPageSize) }" class="ti ti-angle-down mx-0" (click)="this.getAllSongs(this.songsPageNumber + 1)"></a>
                            </div>
                            <div class="col-12 py-4" *ngIf="!(this.allSongs.songs.length > 0)">
                                <p>{{ 'no-songs-label' | transloco }}</p>
                            </div>
                            <!-- ALL SONGS LIST END -->

                            <!-- SEND SONG -->
                            <div class="col-12 text-center">
                                <button *ngIf="!this.sendSongForm && this.allSongs.familyTotal < 3" class="btn my-data btn-primary my-4" (click)="this.sendSongForm = true" >{{'send-song-button-label' | transloco }}</button>
                                <span>
                                    <h4 *ngIf="this.sendSongForm">{{'new-song-title' | transloco }} </h4>
                                    <input type="text" *ngIf="this.sendSongForm" placeholder="{{'new-artist-label' | transloco }} " class="form-control form-opacity text-secondary mt-4" [(ngModel)]="this.song.artist">
                                    <input type="text" *ngIf="this.sendSongForm" placeholder="{{'new-song-label' | transloco }} " class="form-control form-opacity text-secondary" [(ngModel)]="this.song.title">
                                </span>
                                <span>
                                    <button *ngIf="this.sendSongForm" class="btn my-data btn-sm btn-primary mt-2 mb-4 mx-2" (click)="this.postSong(this.song); this.sendSongForm = false"> {{'send-song-label' | transloco }} </button>
                                    <button *ngIf="this.sendSongForm" class="btn my-data btn-sm btn-remove mt-2 mb-4 mx-2" (click)="this.sendSongForm = false"> {{'cancel-button-label' | transloco }} </button>
                                </span>
                            </div>
                            <!-- SEND SONG END -->
                            </div>
                        </div>
                    <!-- SONGS SECTION -->      
                </span>
            </div> 

        </div>
        <!-- boxes end -->
        
    </div>
    <!-- container end -->

</section>