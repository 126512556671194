import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError, } from "rxjs/operators";
import { CreateFamilyRequest, CreateGuestRequest, FamilyPageResponse, UpdateFamilyRequest, UpdateGuestRequest } from "src/app/models/family/family.interface";
import { AuthService } from "../../auth/auth.service";

@Injectable({
    providedIn: 'root',
  })
  export class AdminFamilyService {
    private _environment;
  
    constructor(private httpClient: HttpClient, private _authService: AuthService, @Inject('environment') environment) {
      this._environment = environment
    }
    
    getFamilies(pageSize: number, pageNumber: number, attendanceType: number | null, searchWord: string | null): Observable<FamilyPageResponse> {
      let url = `${this._environment.apiUrl}/families?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    
      if (attendanceType !== null) {
        url += `&attendanceType=${attendanceType}`;
      }
    
      if (searchWord !== null) {
        url += `&searchWord=${encodeURIComponent(searchWord)}`;
      }
    
      return this.httpClient
        .get<FamilyPageResponse>(url)
        .pipe(retry(1), catchError(this.processError));
    }
    

    postFamily(createFamilyRequest: CreateFamilyRequest): Observable<any> {
      return this.httpClient
        .post<CreateFamilyRequest>(
          this._environment.apiUrl + '/auth/register/family',
          createFamilyRequest
        )
        .pipe(retry(1), catchError(this.processError));
    }

    putFamily(id: string, updateFamilyRequest: UpdateFamilyRequest): Observable<any> {
      return this.httpClient
        .put<UpdateFamilyRequest>(
          this._environment.apiUrl + '/family/' + id,
          updateFamilyRequest
        )
        .pipe(retry(1), catchError(this.processError));
    }

    postGuest(id: string, createGuestRequest: CreateGuestRequest): Observable<any> {
      return this.httpClient
        .post<CreateGuestRequest>(
          this._environment.apiUrl + '/' + id + '/guests',
          createGuestRequest
        )
        .pipe(retry(1), catchError(this.processError));
    }

    putGuest(guestId: string, updateGuestRequest: UpdateGuestRequest): Observable<any> {
      return this.httpClient
        .put<UpdateGuestRequest>(
          this._environment.apiUrl + '/guest/' + guestId,
          updateGuestRequest
        )
        .pipe(retry(1), catchError(this.processError));
    }

    deleteGuest(id: string): Observable<any> {
      return this.httpClient
        .delete<any>(
          this._environment.apiUrl + '/guest/' + id
        )
        .pipe(retry(1), catchError(this.processError));
    }

    deleteFamily(id: string): Observable<any> {
      return this.httpClient
        .delete<any>(
          this._environment.apiUrl + '/family/' + id
        ).pipe(retry(1), catchError(this.processError));
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }