import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/auth/user.service';

@Component({
  selector: 'admin-app-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  private userServiceSubscription: Subscription | undefined;
  public username: string;

  constructor(private _authService: AuthService, private _userService: UserService) {}

  ngOnInit() {
    if(this._authService.loggedIn() && this._userService.user == null){
      this._userService.getMe().subscribe((data) => {
        this.username = data.name;
      });
    }

    this.userServiceSubscription = this._userService.currentUser.subscribe(
      currentUser => {
        this.username = currentUser.name;
      }
    );
  }

  toggleSidebar() {
    this.toggleSidebarForMe.emit();
  }

  logOut(){
    this._authService.logOut();
  }
}
