import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { CreateMessageRequest } from "src/app/models/message/message.interface";

@Injectable({
    providedIn: 'root',
  })
  export class MessagesService {
    private _environment;
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment) {
      this._environment = environment
    }
    
   getMessages(id: string): Observable<any> {
      return this.httpClient
        .get<any>(
          this._environment.apiUrl + '/guest/' + id
        )
        .pipe(retry(1), catchError(this.processError));
    }

    postMessage(id: string, message: string): Observable<any> {
      let createMessageRequest: CreateMessageRequest = { body: message }
      return this.httpClient
        .post<any>(
            this._environment.apiUrl + '/family/' + id + '/messages/send',
            createMessageRequest
        )
        .pipe(retry(1));
    }

    deleteMessage(familyId:string, id: string): Observable<any> {
      return this.httpClient
        .delete<any>(
            this._environment.apiUrl + '/family/' + familyId +'/messages/' + id
        )
        .pipe(retry(1), catchError(this.processError));
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }