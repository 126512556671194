import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/auth/user.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/auth/user.service';
import { LanguageService } from 'src/app/services/shared/language.service';
declare var $: any;

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit, OnDestroy, AfterViewInit {
  public user: User = { name: '', firstSurname: '', lastSurname: '', isFamily: false };
  private userServiceSubscription: Subscription | undefined;

  constructor(public languageService: LanguageService, public authService: AuthService, public userService: UserService, private _router: Router) { }

  ngOnInit() {
    if(this.authService.loggedIn() && this.userService.user == null){
      this.userService.getMe().subscribe((data) => {
        this.user.name = this.getUsername(data);
      });
    }

    this.userServiceSubscription = this.userService.currentUser.subscribe(
      currentUser => {
        this.user.name = this.getUsername(currentUser);
      }
    );
  }

  ngAfterViewInit() {
    this.activateMobileMenu();
  }
  
  private getUsername(userData: User): string {
    if (!userData) {
      return '';
    }
  
    if (userData.isFamily) {
      return "Fam. " + (userData.name !== undefined ? userData.name : '');
    } else {
      const fullName = userData.firstSurname
        ? userData.name + " " + (userData.firstSurname !== undefined ? userData.firstSurname : '')
        : userData.name || '';
      return fullName.trim();
    }
  }
  
  changeSiteLanguage(): void {
    this.languageService.changeSiteLanguage();
  }

  logOut(){
    this.authService.logOut();
    this._router.navigate(['/p/home']);
    this.removeClassActive();
  }

  goToRsvp(){
    this._router.navigate(['/p/rsvp']);
    this.removeClassActive();
  }

  goToRsvpData(){
    this._router.navigate(['/p/rsvp-data']);
    this.removeClassActive();
  }

  ngOnDestroy(): void {
    this.userServiceSubscription?.unsubscribe();
  }

  private activateMobileMenu(){
		var $gla_main_menu = $('.gla_main_menu');
		var $gla_main_menu_content = $('.gla_main_menu_content');
		var $gla_main_menu_content_menu = $('.gla_main_menu_content_menu');

		$('.gla_main_menu').on("click", function(e){
			$gla_main_menu_content.toggleClass('active');
			$gla_main_menu_content_menu.toggleClass('active');
			$gla_main_menu.toggleClass('active');
		});

		$('.gla_main_menu_content_menu ul li a').on('click', function(e) {
			$gla_main_menu_content.removeClass('active');
			$gla_main_menu_content_menu.removeClass('active');
			$gla_main_menu.removeClass('active');
		});
  }

  private removeClassActive() {
    $('.gla_main_menu_content').removeClass('active');
    $('.gla_main_menu_content_menu').removeClass('active');
    $('.gla_main_menu').removeClass('active');
  }
}
