import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { concatMap, filter, switchMap, take } from 'rxjs/operators';
import { CreateFamilyRequest, CreateGuestRequest, FamilyPageResponse, FamilyResponse, GuestFamilyResponse, UpdateFamilyRequest, UpdateGuestRequest } from 'src/app/models/family/family.interface';
import { AdminFamilyService } from 'src/app/services/admin/families/admin-family.service';
import { AddEditFamilyComponent } from './add-edit-family/add-edit-family.component';
import { AddEditGuestComponent } from './add-edit-guest/add-edit-guest.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GuestService } from 'src/app/services/guest/guest.service';
import { PutAttendanceGuestComponent } from './put-attendance-guest/put-attendance-guest.component';
import { AttendanceType } from 'src/app/models/guest/guest.interface';
import { AdminModalService } from 'src/app/services/shared/admin-modal.service';
import { PutFeedInfoGuestComponent } from './put-feed-info-guest/put-feed-info-guest.component';
import { FeedInfoService } from 'src/app/services/feed-info/feed-info.service';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { PutFeedInfoCommentsGuestComponent } from './put-feed-info-comments-guest/put-feed-info-comments-guest.component';
import { PutBusInfoGuestComponent } from './put-bus-info-guest/put-bus-info-guest.component';
import { BusInfoRequest, BusInfoTripRequest } from 'src/app/models/admin/bus-info/admin-bus-info.interface';
import { BusInfoService } from 'src/app/services/bus-info/bus-info.service';
import { TripType } from 'src/app/models/guest/bus-info.interface';
import { BusInfoCityResponse } from 'src/app/models/bus-info/bus-info-city.interface';
import { BusInfoTimeResponse } from 'src/app/models/bus-info/bus-info-time.interface';


@Component({
  selector: 'admin-guests-app',
  templateUrl: './admin-guests.component.html',
  styleUrls: ['./admin-guests.component.scss']
})

export class AdminGuestsComponent implements OnInit, AfterViewInit {
  public familiesPage: FamilyPageResponse = { total: 0, families: []};
  public createFamilyRequest: CreateFamilyRequest = { name: "", guests: [], invitedBy: 0, languageType: 0 };
  public updateFamilyRequest: UpdateFamilyRequest = { name: "" };
  public createGuestRequest: CreateGuestRequest = { name: "", firstSurName: "", lastSurName: "" };
  public updateGuestRequest: UpdateGuestRequest = { name: "", firstSurName: "", lastSurName: "" };
  public busInfoRequest: BusInfoRequest = { isNeeded: false, trips: [] };
  public attendanceType: AttendanceType;
  public attendanceTypeFilter: AttendanceType = null;
  public searchWordFilter: string = null;
  public families: FamilyResponse[];
  feedInfo: boolean[] = [false, false, false, false];
  feedInfoComments: string;

  busInfoCities: BusInfoCityResponse[];
  busInfoTimes: BusInfoTimeResponse[];

  dataSource = new MatTableDataSource([]);
  totalItems = 0;
  pageSize = 10;
  currentPage = 0;
  expandedElement: any | null;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(AddEditFamilyComponent) addEditFamilyComponent: AddEditFamilyComponent;
  @ViewChild(AddEditGuestComponent) addEditGuestComponent: AddEditGuestComponent;
  @ViewChild(PutAttendanceGuestComponent) putAttendanceGuestComponent: PutAttendanceGuestComponent;
  @ViewChild(PutFeedInfoGuestComponent) putFeedInfoGuestComponent: PutFeedInfoGuestComponent;
  @ViewChild(PutFeedInfoCommentsGuestComponent) putFeedInfoCommentsGuestComponent: PutFeedInfoCommentsGuestComponent;
  @ViewChild(PutBusInfoGuestComponent) putBusInfoGuestComponent: PutBusInfoGuestComponent;
  constructor(private _adminFamilyService: AdminFamilyService, private _guestService: GuestService, 
    private _adminModalService: AdminModalService, private _feedInfoService: FeedInfoService,
    private _busInfoService: BusInfoService) {}

  ngAfterViewInit(): void {
    this.getCities();
  }

  ngOnInit() {
    this.getFamilies();
    this.dataSource.paginator = this.paginator;
  }

  getFamilies() {
    this._adminFamilyService.getFamilies(this.pageSize, this.currentPage + 1, this.attendanceTypeFilter, this.searchWordFilter).pipe(take(1)).subscribe(data => {
      this.families = data.families;
      this.dataSource.data = data.families;
      this.totalItems = data.total;
    });
  }

  getFilteredFamilies(attendanceType: AttendanceType | null, searchWord: string | null) {
    this.attendanceTypeFilter = attendanceType;
    this.searchWordFilter = searchWord;
    this.getFamilies();
  }

  addFamily() {
    this.addEditFamilyComponent.open("Añadir Familia", "Desde aquí se puede añadir una familia", null, true, false).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this.createFamilyRequest = value.response;
        this._adminFamilyService.postFamily(this.createFamilyRequest).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }

  updateFamily(familyId: string) {
    let family = this.families.find((family) => family.id === familyId)
    this.updateFamilyRequest = this.mapFamilyWithUpdate(family);
    this.addEditFamilyComponent.open("Modificar Familia", "Desde aquí se puede modificar la familia", this.updateFamilyRequest, true, true).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this.updateFamilyRequest = value.response;
        this._adminFamilyService.putFamily(familyId, this.updateFamilyRequest).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }

  addGuest(familyId: string) {
    this.addEditGuestComponent.open("Añadir Invitado", "Desde aquí se puede añadir un invitado", null, true, false).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this.createGuestRequest = value.response;
        this._adminFamilyService.postGuest(familyId, this.createGuestRequest).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }

  updateGuest(guestId: string) {
    let guestFamily = this.findGuestByIdInFamilies(guestId);
    this.updateGuestRequest = this.mapGuestWithUpdate(guestFamily);
    this.addEditGuestComponent.open("Modificar Invitado", "Desde aquí se puede modificar un invitado", this.updateGuestRequest, true, true).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this.updateGuestRequest = value.response;
        this._adminFamilyService.putGuest(guestId, this.updateGuestRequest).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }
  
  putBusInfo(guestId: string) {
    const guestFamily = this.findGuestByIdInFamilies(guestId);
    this.busInfoRequest = this.mapBusInfoWithUpdate(guestFamily);
    
    this.putBusInfoGuestComponent.open("Modificar información de autobús", "Desde aquí se puede modificar información de autobuses de un invitado", 
                        this.busInfoRequest, this.busInfoCities, this.busInfoTimes, true)
        .onClose()
        .pipe(take(1))
        .subscribe(value => {

            if (value.responseButton !== 1) {
                return;
            }
            
            this.updateBusInfo(guestId, value.response);
        });
  }

  private updateBusInfo(guestId: string, response: any) {
    this._busInfoService.putBusInfoIsNeeded(guestId, response.isNeeded)
        .pipe(take(1))
        .subscribe(async (result) => {
            if (!response.isNeeded) {
              this.getFamilies();
              return;
            }
            const updateRequests = result.trips.map((trip: BusInfoTripRequest, index: number) => {
                const responseTrip = response.trips[index];
                if (trip !== responseTrip) {
                    return this.updateBusTrip(trip, responseTrip);
                } else {
                    return of(null);
                }
            });

            forkJoin(updateRequests).subscribe(() => {
                this.getFamilies();
            });
        });
  }

  private updateBusTrip(trip: BusInfoTripRequest, responseTrip: any): Observable<any> {
    return this._busInfoService.putBusTripIsNeeded(trip.id, responseTrip.isNeeded)
        .pipe(
            switchMap(() => {
              if (trip != null && trip.isNeeded) {
                return this._busInfoService.putCity(trip.id, responseTrip.busInfoCity.id);
              }
              else{
                return of(null);
              }
            }),
            switchMap(() => {
                if (responseTrip.busInfoTime != null && trip != null && trip.type === TripType.Back && trip.isNeeded) {
                    return this._busInfoService.putTime(trip.id, responseTrip.busInfoTime.id);
                } else {
                    return of(null);
                }
            })
        );
  }


  findGuestByIdInFamilies(guestId: string): GuestFamilyResponse | undefined {
    for (const family of this.families) {
        const foundGuest = family.guests.find((guest) => guest.id === guestId);
        if (foundGuest) {
            return foundGuest;
        }
    }
    return undefined;
  }

  mapBusInfoWithUpdate(guest: GuestFamilyResponse): BusInfoRequest {
    const mappedGuest: BusInfoRequest = { isNeeded: false, trips: [] };
    mappedGuest.isNeeded = guest.busInfo.isNeeded;
    mappedGuest.trips = guest.busInfo.trips.map(trip => {
      const mappedTrip: BusInfoTripRequest = {
          id: trip.id ? trip.id : null,
          type: trip.type,
          isNeeded: trip.isNeeded,
          busInfoCity: trip.busInfoCity ? {
            name: trip.busInfoCity.name,
            id: trip.busInfoCity.id
          } : null,
          busInfoTime: trip.busInfoTime ? {
              time: trip.busInfoTime.time,
              id: trip.busInfoTime.id
          }: null
      };
      return mappedTrip;
  });

    return mappedGuest;
  }

  mapGuestWithUpdate(guest: GuestFamilyResponse): UpdateGuestRequest {
    
    const mappedGuest: UpdateGuestRequest = { name: "" };
    mappedGuest.name = guest.name;
    mappedGuest.firstSurName = guest.firstSurName;
    mappedGuest.lastSurName = guest.lastSurName;
    mappedGuest.isParent = guest.isParent;
    mappedGuest.type = guest.type;
    mappedGuest.isMale = guest.isMale;
    mappedGuest.canAddCompanion = guest.canAddCompanion;

    return mappedGuest;
  }

  mapFamilyWithUpdate(family: FamilyResponse): UpdateFamilyRequest {
    
    const mappedGuest: UpdateFamilyRequest = { name: "" };
    mappedGuest.name = family.name;

    return mappedGuest;
  }

  putFeedInfo(guestId: string, feedInfo: boolean[]) {
    this.setOldFeedInfoValues(feedInfo);
    this.putFeedInfoGuestComponent.open("Modificar Información alimentaria", "A continuación puedes modificar la información alimentaria del invitado", feedInfo, true)
        .onClose()
        .pipe(
            take(1),
            filter(value => value.responseButton === 1),
            concatMap(value => {

                const observables: Observable<any>[] = [];
                if (this.feedInfo[0] !== value.response[0]) {
                    observables.push(this._feedInfoService.putFeedInfoIsCeliac(guestId, value.response[0]));
                }
                if (this.feedInfo[1] !== value.response[1]) {
                    observables.push(this._feedInfoService.putFeedInfoIsVegetarian(guestId, value.response[1]));
                }
                if (this.feedInfo[2] !== value.response[2]) {
                    observables.push(this._feedInfoService.putFeedInfoIsVegan(guestId, value.response[2]));
                }
                if (this.feedInfo[3] !== value.response[3]) {
                    observables.push(this._feedInfoService.putFeedInfoIsAllergic(guestId, value.response[3]));
                }

                return concat(...observables);
            })
        )
        .subscribe(() => {
         this.getFamilies();
        });
  }

  setOldFeedInfoValues(feedInfo: boolean[]){
    this.feedInfo[0] = feedInfo[0];
    this.feedInfo[1] = feedInfo[1];
    this.feedInfo[2] = feedInfo[2];
    this.feedInfo[3] = feedInfo[3];
  }

  putFeedInfoComments(guestId: string, comments: string) {
    this.putFeedInfoCommentsGuestComponent.open("Modificar comentarios alimentarios", "A continuación puedes modificar los comentarios alimentarios del invitado", comments, true).onClose().pipe(take(1)).subscribe(value => {
     if(value.responseButton == 1){
       this.feedInfoComments = value.response;
       this._feedInfoService.putFeedInfoComments(guestId, this.feedInfoComments).pipe(take(1)).subscribe(data => {
         this.getFamilies();
       });
     }
   });
  }

  putAttendance(guestId: string, attendance: AttendanceType) {
     this.putAttendanceGuestComponent.open("Modificar asistencia", "A continuación puedes modificar la asistencia del invitado", attendance, true).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this.attendanceType = value.response;
        this._guestService.putAttendance(guestId, this.attendanceType).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }

  deleteGuest(id: string){
    this._adminModalService.open("Eliminar invitado", "¿Estás seguro de eliminar el invitado?", false).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this._adminFamilyService.deleteGuest(id).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }

  deleteFamily(id: string){
    this._adminModalService.open("Eliminar familia", "¿Estás seguro de eliminar la familia?", false).onClose().pipe(take(1)).subscribe(value => {
      if(value.responseButton == 1){
        this._adminFamilyService.deleteFamily(id).pipe(take(1)).subscribe(data => {
          this.getFamilies();
        });
      }
    });
  }

  onPageChange(event): void {
    this.currentPage = event.pageIndex;
    this.getFamilies();
  }

  async getCities() {
    this._busInfoService.getCities().pipe(take(1)).subscribe(data => {
      this.busInfoCities = data;
    });
  }

  async getTimesBack(cityId: string) {
    this._busInfoService.getTimesBack(cityId).pipe(take(1)).subscribe(data => {
      this.busInfoTimes = data;
    });
  }
}
