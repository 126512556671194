<div class="modal" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{title}}</h2>
            </div>
            <form>
                <div class="modal-body">
                    <p>{{body}}</p>
                    <input type="checkbox" [(ngModel)]="busInfoRequest.isNeeded" (change)="onChange($event)"> Necesita autobús

                    <span *ngIf="this.busInfoRequest.isNeeded">
                        <span *ngFor="let trip of this.busInfoRequest.trips;">
                            <span class="my-2 row m-4 pb-2">
                                <span *ngIf="trip.type == 0" class="col-12">
                                    <h4 class="card-text text-center text-secondary font-weight-bold">Ida</h4>
                                </span>
                                <span *ngIf="trip.type == 1" class="col-12">
                                    <h4 class="card-text text-center text-secondary font-weight-bold">Vuelta</h4>
                                </span>
                                <span class="col-md-6 col-sm-6 my-1">
                                    <button class="btn my-data btn-sm" [ngClass]="[trip.isNeeded == true ? 'btn-primary' : 'btn-secondary']" (click)="this.putBusTripIsNeeded(trip.type, true)">Sí</button>
                                </span>
                                <span class="col-md-6 col-sm-6 my-1">
                                    <button class="btn my-data btn-sm" [ngClass]="[trip.isNeeded != true ? 'btn-primary' : 'btn-secondary']" (click)="this.putBusTripIsNeeded(trip.type, false)">No</button>
                                </span>

                                <span *ngIf="trip.isNeeded" class="col-12">
                                    <h4 class="card-text text-center col-md-6 col-sm-12 text-left text-secondary">Lugar</h4>
                                    <select *ngIf="trip.type == 0" class="col-md-4 col-sm-12 my-2 px-2 text-center" (change)="putBusInfoCity(trip.type, $event.target.value)" style="box-shadow: none">
                                        <option selected>Selecciona opción</option>
                                        <ng-container *ngFor="let city of busInfoCities">
                                           <option [value]="city.id" [selected]="city.id == selectedCityGo">{{ city.name }}</option>
                                        </ng-container>
                                    </select>
                                    <select *ngIf="trip.type == 1" class="col-md-4 col-sm-12 my-2 px-2 text-center" (change)="putBusInfoCity(trip.type, $event.target.value)" style="box-shadow: none">
                                        <option selected>Selecciona opción</option>
                                        <ng-container *ngFor="let city of busInfoCities">
                                           <option [value]="city.id" [selected]="city.id == selectedCityBack">{{ city.name }}</option>
                                        </ng-container>
                                    </select>
                                </span>

                                <span *ngIf="trip.isNeeded" class="col-12">
                                    <h4 class="card-text text-center col-md-6 col-sm-12 text-center text-secondary">Hora</h4>
                                    <select *ngIf="trip.type == 1" class="col-md-4 col-sm-12 my-2 px-2 text-center" [(ngModel)]="selectedTimeBack" (ngModelChange)="putBusInfoTimeBack($event)" style="box-shadow: none">
                                        <option selected>Selecciona opción</option>
                                        <ng-container *ngFor="let time of busInfoTimes">
                                           <option [value]="time.id">{{ time.time | date:'HH:mm' }}</option>
                                        </ng-container>
                                    </select>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="confirmationOK()">{{submitButton}}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>