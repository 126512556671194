import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { CreateMessageRequest } from "src/app/models/message/message.interface";
import { CreateSongRequest, SongsPageResponse } from "src/app/models/song/song.interface";

@Injectable({
    providedIn: 'root',
  })
  export class SongService {
    private _environment;
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment) {
      this._environment = environment
    }
    
   getSongs(): Observable<any> {
      return this.httpClient
        .get<any>(
          this._environment.apiUrl + '/family/songs'
        )
        .pipe(retry(1), catchError(this.processError));
    }

    getAllSongs(pageNumber: number, pageSize: number): Observable<SongsPageResponse> {
      return this.httpClient
        .get<SongsPageResponse>(
          this._environment.apiUrl + '/family/songs/all?pageNumber=' + pageNumber  + '&pageSize=' + pageSize
        )
        .pipe(retry(1), catchError(this.processError));
    }

    getOthersSongs(pageNumber: number, pageSize: number): Observable<SongsPageResponse> {
      return this.httpClient
        .get<SongsPageResponse>(
          this._environment.apiUrl + '/family/songs/all-but-mine?pageNumber=' + pageNumber  + '&pageSize=' + pageSize
        )
        .pipe(retry(1), catchError(this.processError));
    }

    postSong(createSongRequest: CreateSongRequest): Observable<any> {
      return this.httpClient
        .post<any>(
            this._environment.apiUrl + '/family/songs/send',
            createSongRequest
        )
        .pipe(retry(1));
    }

    deleteSong(id: string): Observable<any> {
      return this.httpClient
        .delete<any>(
            this._environment.apiUrl + '/family/songs/' + id
        )
        .pipe(retry(1), catchError(this.processError));
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }