import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

export interface SearchGuestResponse {
    id: string;
    name: string;
    firstSurName: string;
    lastSurName: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private _environment;

  constructor(private httpClient: HttpClient, @Inject('environment') environment) {
    this._environment = environment
  }
  
 searchGuest(data: any): Observable<SearchGuestResponse[]> {
    return this.httpClient
      .post<SearchGuestResponse[]>(
        this._environment.apiUrl + '/guests/search',
        JSON.stringify(data)
      )
      .pipe(retry(1), catchError(this.processError));
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    console.log(message);
    return throwError(() => {
      message;
    });
  }
}