
<div class="modal" style="display: block;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="border-0 mt-4">
                <h2 class="modal-title text-center">{{title}}</h2>
            </div>
            <div class="modal-body border-0">
                <p class="text-center m-0">{{body}}</p>
                <input *ngIf="isInput" type="text" [(ngModel)]="inputText" id="inputText" name="inputText" class="form-control form-opacity" placeholder="{{inputPlaceholder}}" />
            </div>
            <div class="text-center p-5">
                <button mat-raised-button (click)="confirmationOK()" style="background-color: #fff;">{{submitButton}}</button>
                <button mat-raised-button (click)="confirmationNOK()" style="background-color: #fff;">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>
