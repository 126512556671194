import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-wedshoots',
  templateUrl: './wedshoots.component.html',
  styleUrls: ['./wedshoots.component.scss']
})
export class WedshootsComponent {
  private _environment;

  constructor(@Inject('environment') environment) {
    this._environment = environment;
  }

  isOneWeekAgoToDate(): boolean {
    const today = new Date();
    const targetDate = new Date(this._environment.date);
    targetDate.setDate(targetDate.getDate() - 7);
    return today >= targetDate;
  }
}
