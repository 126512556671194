import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HttpHeaders, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslocoService } from "@ngneat/transloco";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, finalize, switchMap, take } from "rxjs/operators";
import { Token } from "../models/auth/token.interface";
import { User } from "../models/auth/user.interface";
import { AuthService } from "./auth/auth.service";
import { UserService } from "./auth/user.service";
import { LoadingService } from "./shared/loading.service";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    }),
  };

  constructor(private _authService: AuthService, private _userService: UserService, private _translocoService: TranslocoService, private _loadingService: LoadingService, private _snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this._loadingService.show();

    const newReq = req.clone({
      params: (req.params ? req.params : new HttpParams()),
      headers: this.httpHeader.headers
    });

    var code = newReq.params.get('code');

    var newestReq = this.addToken(newReq);

    return next.handle(newestReq).pipe(
      catchError((error: any) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !req.url.includes('refresh')
        ) {
          return this.handleUnauthorizedError(req, next);
        } else {
          this.handleError(error);
          return throwError(error);
        }
      }), finalize(() => {
          this._loadingService.hide();
        })
    );
  }

  private handleUnauthorizedError(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._authService.getTokenByRefreshToken().pipe(
        switchMap((token: Token) => {
          this._authService.setToken(token);
        return this._userService.getMe().pipe(
          switchMap((user: User) => {
            this.refreshTokenSubject.next(token);
            this.isRefreshing = false;
            return next.handle(this.addToken(request));
          }),
          catchError((error) => {
            this.handleError(error);
            this.isRefreshing = false;
            return throwError(error);
          })
        );
      }),
      catchError((error) => {
        this.handleError(error);
        this.isRefreshing = false;
        return throwError(error);
      }),
      finalize(() => {
        this.refreshTokenSubject.complete();
      })
    );

    } else {
      return this.refreshTokenSubject.pipe(
        switchMap(() => {
          return next.handle(this.addToken(request));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this._authService.getToken();
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return request;
  }

  private handleError(error: HttpErrorResponse): void {

    let errorMessage;

    if (error.status !== 400) {
      this._translocoService.selectTranslate('server-error-message').pipe(take(1)).subscribe((msg) => {
        errorMessage = msg;
      });
  
      this._snackBar.open(errorMessage, 'Cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['snackbar','snackbar-error']
      });
    }
  }
}