import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { AdminLoginRequest } from "src/app/models/admin/auth/admin-login.interface";
import { Token } from "src/app/models/auth/token.interface";

@Injectable()
export class AuthService {
  private _environment;

  constructor(private httpClient: HttpClient, @Inject('environment') environment) { 
    this._environment = environment;
  }

  loggedIn(){
    let hasToken = false;
    if (localStorage.getItem('token')){
      hasToken = true;
    }
    return hasToken;
  }

  logOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

  getToken(){
    return localStorage.getItem('token');
  }

  getValidCode(){
    return localStorage.getItem('validcode');
  }

  getRefreshToken(){
    return localStorage.getItem('refreshToken');
  }

  setToken(authToken: Token){
    localStorage.setItem('token', authToken.accessToken);
    localStorage.setItem('refreshToken', authToken.refreshToken);
  }

  setOnlyToken(accessToken: string){
    localStorage.setItem('token', accessToken);
  }

  getAdminToken(adminLogin: AdminLoginRequest): Observable<Token> {
    return this.httpClient
    .post<Token>(
      this._environment.apiUrl + '/auth/admin/login/',
      adminLogin
    ).pipe(retry(1), catchError(this.processError));
  }

  getGuestToken(id: string): Observable<Token> {
    return this.httpClient
    .post<Token>(
      this._environment.apiUrl + '/auth/login/' + id + "/" + this.getValidCode(),
      null
    ).pipe(retry(1), catchError(this.processError));
  }

  getTokenByRefreshToken() : Observable<any> {
      return this.httpClient
      .post<any>(
        this._environment.apiUrl + '/auth/refresh',
        { refreshToken: this.getRefreshToken() }
      ).pipe(retry(1), catchError(this.processError));
  }

  getUserRole() : string {
    let token = this.getToken();
    if(token != null){
      let jwtData = this.getToken().split('.')[1]
      let decodedJwtJsonData = window.atob(jwtData)
      let decodedJwtData = JSON.parse(decodedJwtJsonData)
      let userRole : string = decodedJwtData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      return userRole;
    }
    else 
      return null;
  }

  isAdmin(): boolean {
    if(this.getUserRole() === 'Admin'){
      return true;
    }
    else {
      return false;
    }
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    console.log(message);
    return throwError(() => {
      message;
    });
  }
}