import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { CodeService } from 'src/app/services/auth/code.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent {
  public code: string = '';
  public codeErrorMessage: string = '';

  constructor(private _codeService: CodeService, private _router: Router, private _translocoService: TranslocoService) { }

  submitCode(code: string) {
    this._codeService.checkCode(code).then((res: any) => {
      if(res) {
        this._router.navigate(['/p/home']);
      }
      else {
        this.code = '';
        this.codeErrorMessage = this._translocoService.translate('error-code');
        setTimeout(() => {
          this.codeErrorMessage = '';
       }, 3000);
      }
    });
  }
}
