import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostTranslationResponse } from 'src/app/models/post/post.interface';
import { PostService } from 'src/app/services/posts/post.service';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.css']
})
export class PostDetailComponent implements OnInit  {
  public postTranslation: PostTranslationResponse = null;

  constructor(private _postService: PostService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.postTranslation = history.state.postTranslation;
    });
  }
}
