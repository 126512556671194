import { BusInfoCityResponse } from "../bus-info/bus-info-city.interface";
import { BusInfoTimeResponse } from "../bus-info/bus-info-time.interface";

export interface BusInfoResponse {
    id: string;
    isNeeded: boolean;
    trips: BusTripResponse[];
}

export interface BusTripResponse {
    id: string;
    type: TripType;
    isNeeded: boolean;
    busInfoCity: BusInfoCityResponse;
    busInfoTime: BusInfoTimeResponse;
}

export enum TripType {
    Go,
    Back
}