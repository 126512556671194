import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { FeedInfoCommentsRequest } from "src/app/models/feed-info/feed-info-comments.interface";

@Injectable({
    providedIn: 'root',
})
  export class FeedInfoService {
    private _environment;
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment) {
      this._environment = environment
    }

    putFeedInfoIsCeliac(id: string, isCeliac: boolean): Observable<any> {
        return this.httpClient
        .put<any>(
          this._environment.apiUrl + '/guest/' + id + '/feed-info/celiac/' + isCeliac,
          null
        )
        .pipe(retry(1), catchError(this.processError));
    }

    putFeedInfoIsVegetarian(id: string, isVegetarian: boolean): Observable<any> {
      return this.httpClient
      .put<any>(
        this._environment.apiUrl + '/guest/' + id + '/feed-info/vegetarian/' + isVegetarian,
        null
      )
      .pipe(retry(1), catchError(this.processError));
    }

    putFeedInfoIsVegan(id: string, isVegan: boolean): Observable<any> {
      return this.httpClient
      .put<any>(
        this._environment.apiUrl + '/guest/' + id + '/feed-info/vegan/' + isVegan,
        null
      )
      .pipe(retry(1), catchError(this.processError));
    }

    putFeedInfoIsAllergic(id: string, isAllergic: boolean): Observable<any> {
      return this.httpClient
      .put<any>(
        this._environment.apiUrl + '/guest/' + id + '/feed-info/allergic/' + isAllergic,
        null
      )
      .pipe(retry(1), catchError(this.processError));
    }

    putFeedInfoComments(id: string, comments: string): Observable<any> {
        let feedInfoComments: FeedInfoCommentsRequest = { comments: comments };
        return this.httpClient
        .put<any>(
          this._environment.apiUrl + '/guest/' + id + '/feed-info/comments',
          feedInfoComments 
        )
        .pipe(retry(1), catchError(this.processError));
      }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }