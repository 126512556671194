<h1>Canciones</h1>
<div class="card shadow-sm p-3 mb-5 bg-white">
  <ng-container *ngFor="let family of songs">
    <h2 class="text-center">{{ family.familyName }}</h2>
      <mat-list>
        <mat-list-item *ngFor="let song of family.songs">
          <p class="text-center text-secondary"><strong>{{ song.title }}</strong> - {{ song.artist }}</p>
        </mat-list-item>
      </mat-list>
  </ng-container>
  <mat-paginator [length]="total" [pageSize]="pageSize" [pageIndex]="pageNumber"
               [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)">
  </mat-paginator>
</div>