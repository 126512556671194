<section class="gla_section">
    <div class="container text-center">
        <p><img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt=""></p>
        <h1>{{ 'rsvp' | transloco }}</h1><br><br><br>
          <div *ngIf="!this.authService.loggedIn()">
            <p>{{ 'searchGuest' | transloco }}</p>
            <div class="row">
                <div class="col-md-8 col-md-push-2 p-4">
                    <form method="POST">
                        <div class="row">
                            <div class="col-md-3">
                                <input type="text" [(ngModel)]="searchGuest.searchname" id="searchname" name="searchname" class="form-control form-opacity" placeholder="{{ 'name' | transloco }} *" (ngModelChange)="changed($event)"/>
                            </div>
                            <div class="col-md-4">
                            <input type="text" [(ngModel)]="searchGuest.searchfirstsurname" id="searchfirstsurname" name="searchfirstsurname" class="form-control form-opacity" placeholder="{{ 'firstSurname' | transloco }} *" (ngModelChange)="changed($event)"/>
                            </div>
                            <div class="col-md-4">
                            <input type="text" [(ngModel)]="searchGuest.searchlastsurname" id="searchlastsurname" name="searchlastsurname" class="form-control form-opacity" placeholder="{{ 'lastSurname' | transloco }} " (ngModelChange)="changed($event)"/>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <button class="btn my-data btn-search ti ti-search" (click)="submitSearch()"></button>
                              </div>
                              <div class="col-6">
                                <button (click)="clear()" class="btn my-data btn-remove ti ti-trash"></button>
                              </div>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- SEARCH GUESTS LIST -->
                <div *ngIf="foundGuests">
                  <div class="col-md-8 col-md-push-2" *ngIf="foundGuests.length > 0">
                    <span *ngFor="let guest of foundGuests;">
                        <div class="mt-4 py-2">
                            <button class="col-12 btn btn-guest my-data" (click)="this.get(guest)">
                              <span class="col-12 row fs-2">{{ guest.name }} {{ guest.firstSurName }} {{ guest.lastSurName }}
                              </span>
                            </button>
                        </div>
                    </span>
                  </div>
                  <div class="col-md-8 col-md-push-2" *ngIf="foundGuests.length == 0">
                    <div class="mt-4 py-2 text-center">
                      <span class="fs-2">{{ 'not-found-guests-label' | transloco }} </span>
                    </div>
                  </div>
                </div>
              <!-- SEARCH GUESTS LIST END -->
            </div>
          </div>
          <div *ngIf="this.authService.loggedIn()">
            <button class="btn my-data btn-secondary" [routerLink]="['/p/rsvp-data']">
              {{ 'rsvp' | transloco }}
            </button>
          </div>

    </div>
    <!-- container end -->

</section>