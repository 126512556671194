import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AdminLoginRequest } from 'src/app/models/admin/auth/admin-login.interface';
import { Token } from 'src/app/models/auth/token.interface';

@Component({
  selector: 'admin-app-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']})
export class AdminLoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private _authService: AuthService
    ) { 
        // redirect to home if already logged in
        if (this._authService.loggedIn()) { 
            //this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        var adminLoginRequest : AdminLoginRequest = { username: this.f.username.value, password: this.f.password.value }
        this._authService.getAdminToken(adminLoginRequest).subscribe((res : Token) =>{
            if(res){
                this._authService.setToken(res);
                this.router.navigate(['/a/home']);
            }
        }, error => {
            this.error = error;
            this.loading = false;
        });
    }
}
