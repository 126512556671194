import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminAppComponent } from './pages/admin/admin-app.component';
import { AdminDashboardComponent } from './pages/admin/dashboard/admin-dashboard.component';
import { AdminHeaderComponent } from './pages/admin/header/admin-header.component';
import { AdminHomeComponent } from './pages/admin/home/admin-home.component';
import { AdminSidenavComponent } from './pages/admin/sidenav/admin-sidenav.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdminFamilyService } from './services/admin/families/admin-family.service';
import { AuthService } from './services/auth/auth.service';
import { CodeOrAuthGuard } from './services/auth/code-or-authguard.service';
import { CodeService } from './services/auth/code.service';
import { UserService } from './services/auth/user.service';
import { BusInfoService } from './services/bus-info/bus-info.service';
import { CustomInterceptor } from './services/custom.interceptor';
import { FeedInfoService } from './services/feed-info/feed-info.service';
import { GuestService } from './services/guest/guest.service';
import { MessagesService } from './services/messages/message.service';
import { ModalService } from './services/shared/modal.service';
import { SongService } from './services/songs/song.service';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LandingPageComponent } from './pages/public/landing-page/landing-page.component';
import { PublicAppComponent } from './pages/public/public-app.component';
import { TranslocoRootModule } from './transloco-root.module';
import { RsvpComponent } from './pages/public/rsvp/rsvp.component';
import { FooterComponent } from './pages/public/footer/footer.component';
import { TopNavComponent } from './pages/public/top-nav/top-nav.component';
import { CodeComponent } from './pages/public/code/code.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScheduleComponent } from './pages/public/schedule/schedule.component';
import { GalleryComponent } from './pages/public/gallery/gallery.component';
import { RsvpDataComponent } from './pages/public/rsvp-data/rsvp-data.component';
import { AdminModalComponent } from './pages/admin/shared/modal/admin-modal.component';
import { AudioPlayerComponent } from './pages/public/shared/music/audio-player.component';
import { LoaderComponent } from './pages/public/shared/loader/loader.component';
import { AdminLoginComponent } from './pages/admin/login/admin-login.component';
import { UserAuthGuard } from './services/auth/user-authguard.service';
import { AdminAuthGuard } from './services/auth/admin-authguard.service';
import { PostsComponent } from './pages/public/posts/posts.component';
import { AdminGuestsComponent } from './pages/admin/guests-info/admin-guests.component';
import { AddEditFamilyComponent } from './pages/admin/guests-info/add-edit-family/add-edit-family.component';
import { AddEditGuestComponent } from './pages/admin/guests-info/add-edit-guest/add-edit-guest.component';
import { PostDetailComponent } from './pages/public/posts/postdetail/post-detail.component';
import { AdminPostsComponent } from './pages/admin/posts-info/admin-posts.component';
import { AdminPostService } from './services/admin/posts/admin-post.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { PutAttendanceGuestComponent } from './pages/admin/guests-info/put-attendance-guest/put-attendance-guest.component';
import { MatButtonModule } from '@angular/material/button';
import { ModalComponent } from './pages/shared/modal/modal.component';
import { AdminModalService } from './services/shared/admin-modal.service';
import { PutFeedInfoGuestComponent } from './pages/admin/guests-info/put-feed-info-guest/put-feed-info-guest.component';
import { PutFeedInfoCommentsGuestComponent } from './pages/admin/guests-info/put-feed-info-comments-guest/put-feed-info-comments-guest.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WedshootsComponent } from './pages/public/shared/wedshoots/wedshoots.component';
import { PutBusInfoGuestComponent } from './pages/admin/guests-info/put-bus-info-guest/put-bus-info-guest.component';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { AdminGuestsMessagesComponent } from './pages/admin/guests-messages/guests-messages.component';
import { AdminGuestsSongsComponent } from './pages/admin/guests-songs/guests-songs.component';

@NgModule({
  declarations: [
    AppComponent,

    /* PUBLIC */
    PublicAppComponent,
    LandingPageComponent,
    RsvpComponent,
    RsvpDataComponent,
    CodeComponent,
    ScheduleComponent,
    GalleryComponent,
    PostsComponent,
      PostDetailComponent,
    ModalComponent,
    AudioPlayerComponent,
    FooterComponent,
    TopNavComponent,
    WedshootsComponent,

    /* ADMIN */
    AdminAppComponent,
    AdminLoginComponent,
    AdminSidenavComponent,
    AdminHeaderComponent,
    AdminHomeComponent,
    AdminDashboardComponent,
    AdminGuestsComponent,
        AddEditFamilyComponent,
        AddEditGuestComponent,
        PutAttendanceGuestComponent,
        PutFeedInfoGuestComponent,
        PutFeedInfoCommentsGuestComponent,
        PutBusInfoGuestComponent,
    AdminPostsComponent,
    AdminModalComponent,
    AdminGuestsMessagesComponent,
    AdminGuestsSongsComponent,
    
    MarkdownPipe,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    /* MATERIAL IMPORTS */
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSelectModule,

    InfiniteScrollModule,
    HttpClientModule,
    TranslocoRootModule
  ],
  providers: [UserService, AuthService, UserAuthGuard, AdminAuthGuard, CodeService, CodeOrAuthGuard, ModalService, GuestService, BusInfoService, FeedInfoService, MessagesService, SongService,

    /* ADMIN */
    AdminPostService,
    AdminFamilyService,
    AdminModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    },
    {provide: 'environment', useValue: environment}],
  bootstrap: [AppComponent],
})
export class AppModule {}
