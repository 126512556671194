import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Subject, take } from 'rxjs';
import { BusInfoRequest } from 'src/app/models/admin/bus-info/admin-bus-info.interface';
import { BusInfoCityResponse } from 'src/app/models/bus-info/bus-info-city.interface';
import { BusInfoTimeResponse } from 'src/app/models/bus-info/bus-info-time.interface';
import { TripType } from 'src/app/models/guest/bus-info.interface';
import { ModalResponse } from 'src/app/models/modal/modal.interface';
import { BusInfoService } from 'src/app/services/bus-info/bus-info.service';

@Component({
  selector: 'app-put-bus-info',
  templateUrl: './put-bus-info-guest.component.html',
  styleUrls: ['./put-bus-info-guest.component.scss']
})
export class PutBusInfoGuestComponent implements OnInit {
  modalResponse: ModalResponse = { responseButton: 3, response: null}
  onClose:Subject<ModalResponse> = new Subject<ModalResponse>();
  isOpen = false;
  isInput = false;
  isEdit = false;
  submitButton: string = "Guardar";
  busInfoRequest: BusInfoRequest = { isNeeded: false, trips: [] }
  busInfoCities: BusInfoCityResponse[];
  busInfoTimes: BusInfoTimeResponse[];
  selectedTimeBack: string = "";
  selectedCityBack: string = "";
  selectedCityGo: string = "";

  private element: any;
  public title: string;
  public body: string;

  constructor(private el: ElementRef, private _busInfoService: BusInfoService, private cdr: ChangeDetectorRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
      this.close();
      document.body.appendChild(this.element);
      this.element.addEventListener('click', (el: any) => {
          if (el.target.className === 'modal') {
            this.close();
          }
      });
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    this.isOpen = false;
  }

  onChange(event: Event): void {
    const isChecked: boolean = event.target['checked'];
    this.busInfoRequest.isNeeded = isChecked;

    if(this.busInfoRequest.isNeeded){
      this.busInfoRequest.trips = [{
        id: null,
        type: TripType.Go,
        isNeeded: false,
        busInfoCity: null,
        busInfoTime: null
      }, {
        id: null,
        type: TripType.Back,
        isNeeded: false,
        busInfoCity: null,
        busInfoTime: null
      }]
    }
  }

  putBusInfoTimeBack() {
    const filteredTrips = this.busInfoRequest.trips
    .filter(t => t.type === 1)
    .map((trip, index) => ({ index, trip }));

    filteredTrips.forEach(({ trip }) => {
      const time = this.busInfoTimes.find(t => t.id === this.selectedTimeBack);
        trip.busInfoTime = { id: time.id, time: time.time };
    });
  }

  putBusInfoCity(tripType: TripType, selectedCity: string) {
    console.log("selectedCity: " + selectedCity);
    console.log("this.selectedCityBack: " + this.selectedCityBack);
    if(tripType == TripType.Back && selectedCity != this.selectedCityBack){
      this.setTimesBack(selectedCity);
    }
    const filteredTrips = this.busInfoRequest.trips
    .filter(t => t.type === tripType)
    .map((trip, index) => ({ index, trip }));

    filteredTrips.forEach(({ trip }) => {
        const city = this.busInfoCities.find(t => t.id === selectedCity);
        trip.busInfoCity = { id: city.id, name: city.name };
    });

    this.selectedCityBack = selectedCity;
  }

  putBusTripIsNeeded(tripType: TripType, isNeeded: boolean){
    const filteredTrips = this.busInfoRequest.trips
    .filter(t => t.type === tripType)
    .map((trip, index) => ({ index, trip }));

    filteredTrips.forEach(({ trip }) => {
      trip.isNeeded = isNeeded;
    });
  }

  setData(title: string, body: string, busInfoRequest: BusInfoRequest, busInfoCities: BusInfoCityResponse[], busInfoTimes: BusInfoTimeResponse[]){
    this.busInfoCities = busInfoCities;
    this.busInfoTimes = busInfoTimes;

    this.title = title;
    this.body = body;
    this.busInfoRequest = busInfoRequest;
    this.busInfoRequest.isNeeded = busInfoRequest.isNeeded;

    if(this.busInfoRequest.trips.length > 0){
      const cityGo = this.busInfoRequest.trips.find(trip => trip.type === TripType.Go && trip.isNeeded)?.busInfoCity;
      if (cityGo) {
          this.selectedCityGo = cityGo.id;
      }

      const cityBack = this.busInfoRequest.trips.find(trip => trip.type === TripType.Back && trip.isNeeded)?.busInfoCity;
      if (cityBack) {
          this.selectedCityBack = cityBack.id;
      }

      const timeBack = this.busInfoRequest.trips.find(trip => trip.type === TripType.Back && trip.isNeeded)?.busInfoTime;
      if (timeBack) {
          this.selectedTimeBack = timeBack.id;
      }
    }

    this.setTimesBack(this.selectedCityBack);

    this.cdr.detectChanges();
  }

  setTimesBack(selectedCity: string){
    this._busInfoService.getTimesBack(selectedCity).subscribe((data) => {
      this.busInfoTimes = data;
    });
  }

  refresh(){
    this.cdr.detectChanges();
  }

  open(title: string, body: string, busInfoRequest: BusInfoRequest, busInfoCities: BusInfoCityResponse[], busInfoTimes: BusInfoTimeResponse[], isInput: boolean, firstButton?: string) {
    this.setData(title, body, busInfoRequest, busInfoCities, busInfoTimes);

    if(firstButton != null){
        this.submitButton = firstButton;
    }
    
    if(isInput){
        this.isInput = true;
    }else{
        this.isInput = false;
    }

    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    this.isOpen = true;

    return {
        onClose:()=>this.onClose
    }
  }

  confirmationOK(){
    this.modalResponse.responseButton = 1
    if(this.isInput){
      this.modalResponse.response = this.busInfoRequest;
    }
    this.setOnClose();
  }

  confirmationNOK(){
    this.modalResponse.responseButton = 2;
    this.setOnClose();
  }

  setOnClose(){
    this.close();
    this.onClose.next(this.modalResponse);
  }

  compareByOptionId(idFist, idSecond) {
    //return idFist && idSecond && idFist.id == idSecond.id;
    console.log("COMPARE")
    return true;
 }
}
