<div class="modal" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{title}}</h2>
            </div>
            <form>
                <div class="text-center">
                    <p>{{body}}</p>
                    <textarea class="mx-1" [(ngModel)]="this.comments"></textarea>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="confirmationOK()">{{submitButton}}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>