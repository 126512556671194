import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponse } from 'src/app/models/modal/modal.interface';
import { ModalComponent } from 'src/app/pages/shared/modal/modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
    private modal: ModalComponent;
    modalResponse: ModalResponse;
    onClose:Subject<ModalResponse>=new Subject<ModalResponse>();

    add(modal: ModalComponent) {
        this.modal = modal;
    }

    remove(modal: ModalComponent) {
        this.modal = null;
    }

    open(title: string, body: string, isInput: boolean, inputPlaceholder?: string, firstButton?: string) {
        this.modal.open(title, body, isInput, inputPlaceholder, firstButton).onClose().subscribe(value => {
            this.onClose.next(value);
        });

        return {
            onClose:()=>this.onClose
        }
    }
}