<div class="col-md-8 col-md-push-2 my-2 p-4 px-0">
    <p><img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt=""><img class="mt-5" src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt="" style="transform:rotate(180deg);"></p>
    <div class="card-my-data my-5 p-4 pb-5">
        <h1 style="margin-bottom: 10px; margin-top: 20px"><div class="ti ti-gallery text-secondary"></div> {{ 'rsvp-data-images-title-label' | transloco }}</h1>
        <h3 class="mt-5 mb-3 text-secondary">1.{{ 'rsvp-data-images-download-label' | transloco }}</h3>
        <div class="row text-center">
            <div class="col-md-8 offset-md-2">
                <div class="col-md-6">
                    <a rel="nofollow" href="https://app.appsflyer.com/id660256196?pid=WP-iOS-ES&amp;c=WP-ES-LANDINGS&amp;s=es">
                        <img height="50" src="https://cdn1.bodas.net/assets/img/apps/apple-store-badge.svg">
                    </a>
                </div>
                <div class="col-md-6">
                    <a rel="nofollow" href="https://app.appsflyer.com/net.bodas.android.wedshoots?pid=WP-Android-ES&amp;c=WP-ES-LANDINGS">
                        <img height="50" src="https://cdn1.bodas.net/assets/img/apps/google-play-badge.svg">
                    </a>
                </div>
            </div>
        </div>
        
        <h3 class="mt-5 mb-3 text-secondary">2.{{ 'rsvp-data-images-access-label' | transloco }}:</h3>
        <p class="alert alert-warning center" *ngIf="!isOneWeekAgoToDate()">{{ 'rsvp-data-images-availability-label' | transloco }}</p>
        <span *ngIf="isOneWeekAgoToDate()">
            {{ 'rsvp-data-images-access-code-label' | transloco }}: <strong>ES73a6c66c</strong>
        </span>
        <h3 class="mt-5 mb-3 text-secondary">3.{{ 'rsvp-data-images-upload-label' | transloco }}</h3>
        <button class="mt-5 btn my-data btn-secondary" routerLink="/p/gallery">{{ 'rsvp-data-images-goto-label' | transloco }} Galería</button>
    </div>
</div>
