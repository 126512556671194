<div>
  <h1>Bienvenido al Dashboard</h1>
  <label>Total adultos: {{ this.totalAdults }}</label>
  <label>Total niños: {{ this.totalKids }}</label>
  <label>Total bebés: {{ this.totalBabies }}</label>
  <div class="row text-center justify-content-center">
    <h2>Información sobre los invitados</h2>
    <div class="col-4 mx-2 bg-white shadow">
      <h3>Asistencias</h3>
      <canvas #chartAttendanceGuests width="400" height="400"></canvas>
    </div>
    <div class="col-4 mx-2 bg-white shadow">
      <h3>Información alimentaria</h3>
      <canvas #chartFeedInfoGuests width="400" height="400"></canvas>
    </div>
  </div>
  
  <div *ngIf="dashboardBusInfoData">
    <h2>Información de autobuses</h2>
    <div *ngFor="let trip of dashboardBusInfoData.trips" class="m-4">
      <h3>{{ busTripTypeEnum[trip.type] }}</h3>
      <table mat-table [dataSource]="trip.citiesTimes" class="mat-elevation-z8">
  
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>Ciudad</th>
          <td mat-cell *matCellDef="let element">{{ element.city }}</td>
        </ng-container>
  
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Hora</th>
          <td mat-cell *matCellDef="let element">{{ element.timesGuestsCount[0].time | date:'HH:mm:ss' }}</td>
        </ng-container>
  
        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef>Cantidad de Invitados</th>
          <td mat-cell *matCellDef="let element">{{ element.timesGuestsCount[0].count }}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="['city', 'time', 'count']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['city', 'time', 'count'];"></tr>
      </table>
    </div>
  </div>
</div>