<section class="gla_section">
    <div class="container text-center">
        <h1 [innerHTML]="postTranslation.title | markdown"></h1>
        <div class="tabs">
            <div class="tab-content">
                <div class="tab-pane fade in active">
                <!-- boxes -->
                <div class="top-margin-lg row text-left">
                    <!-- item -->
                    <ng-container *ngIf="this.postTranslation">
                        <div class="col-md-10 offset-md-1 col-sm-6">
                            <span class="gla_news_block">
                                <!--<span class="gla_news_img" [innerHTML]="postTranslation.image | markdown"></span>-->
                                <p [innerHTML]="postTranslation.shortDescription | markdown" class="mt-4"></p>
                                <p class="pb-4" [innerHTML]="postTranslation.content | markdown"></p>
                            </span>
                        </div>
                    </ng-container>
                    <div class="text-center">
                        <a class="btn my-data btn-sm text-center text-white" routerLink="/p/posts" role="button">{{ 'back-button-label' | transloco }}</a>
                    </div>
                </div>
                <!-- boxes end -->
                </div>
                <!-- tab end -->
            </div>  
        </div>
    
    </div>

</section>