<!-- Header -->
<header class="gla_header gla_second_step">

  <nav class="gla_light_nav gla_image_bck gla_fixed" style="background-color: rgb(255, 255, 255);">
    <div class="gla_over" data-color="#fff" data-opacity="0.8" style="background-color: rgb(255, 255, 255);">
    </div>
    
    <div class="container">

      <div class="gla_logo_container clearfix">
        <div class="gla_logo_txt">
          <!-- Logo -->
          <a href="/p/home" class="gla_logo">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 80 283.5 120" xml:space="preserve" preserveAspectRatio="none">
              <style type="text/css">
                .st0{opacity:0.5;}
                .st1{fill:#132517;}
                .st2{fill:#5E5E5E;}
                .st3{font-family:'PlayfairDisplaySC-Regular';}
                .st4{font-size:64.6936px;}
                .st5{opacity:0.3;fill:#127334;}
                .st6{opacity:0.7;}
                .st7{fill:none;stroke:#466829;stroke-width:0.7495;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
              </style>
              <g>
                <text transform="matrix(1.0428 0 0 1 107.2271 129.7852)" class="st2 st3 st4">T</text>
                <text transform="matrix(1.0428 0 0 1 63.6978 186.3428)" class="st2 st3 st4">S</text>
                <path class="st5" d="M83.1,105.7c0,3.5-3,6.3-6.6,6.3s-6.6-2.8-6.6-6.3s3-6.3,6.6-6.3S83.1,102.2,83.1,105.7z"/>
                <path class="st5" d="M134.5,161.5c0,3.5-3,6.3-6.6,6.3c-3.6,0-6.6-2.8-6.6-6.3c0-3.5,3-6.3,6.6-6.3
                  C131.5,155.1,134.5,158,134.5,161.5z"/>
              </g>
              </svg>
          </a>

          <!-- Text Logo -->
          <div class="gla_logo_und">{{ 'weddingDate' | transloco }}</div>
        </div>
      </div>

      <!-- Menu -->
      <div class="gla_main_menu gla_main_menu_mobile">

        <div class="gla_main_menu_icon">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <!-- <b>MENÚ</b> -->
          <b class="gla_main_menu_icon_b"></b>
        </div>
      </div>

      <!-- Menu Content -->
      <div class="gla_main_menu_content gla_image_bck" data-color="rgba(0,0,0,0.9)" style="background-color: rgba(0, 0, 0, 0.9);">
        <!-- Over -->
        <div class="gla_over" data-color="#000" data-opacity="0.7" style="background-color: rgb(0, 0, 0); opacity: 0.7;"></div>
      </div>

      <div class="gla_main_menu_content_menu gla_wht_txt text-right">
        <div class="container text-center">
            <div class="menu-menu-1-container">
              <ul>
                <li class="gla">
                  <a routerLink="home">{{ 'home' | transloco }}</a>
                </li>

                <li class="gla">
                  <a routerLink='posts'>{{ 'posts' | transloco }}</a>
                </li>

                <li class="gla">
                  <a routerLink='schedule'>{{ 'schedule' | transloco }}</a>
                </li>
      
                <li class="gla">
                  <a routerLink='gallery'>{{ 'gallery' | transloco }}</a>
                </li>

                <li class="gla">
                  <a *ngIf="this.authService.loggedIn()" (click)="goToRsvpData()">{{ 'myData' | transloco }}</a>
                </li>

                <li class="gla">
                  <a *ngIf="!this.authService.loggedIn()" (click)="goToRsvp()">{{ 'login' | transloco }}</a>
                  <a *ngIf="this.authService.loggedIn()" (click)="logOut()">{{ 'logout' | transloco }}, {{ this.user.name }}</a>
                </li>

                <li>
                  <a class="button" role="button" (click)="changeSiteLanguage()" data-toggle="dropdown" > {{ this.languageService.getSiteLanguage() }} </a>
              </li>
              </ul>
            </div>
        </div>
        <!-- container end -->
      </div>
      <!-- menu content end -->

      <!-- Top Menu -->
      <div class="gla_default_menu">
        <ul>
          <li class="gla">
            <a routerLink="home">{{ 'home' | transloco }}</a>
          </li>

          <li class="gla">
            <a routerLink='posts'>{{ 'posts' | transloco }}</a>
          </li>

          <li class="gla">
            <a routerLink='schedule'>{{ 'schedule' | transloco }}</a>
          </li>

          <li class="gla">
            <a routerLink='gallery'>{{ 'gallery' | transloco }}</a>
          </li>

          <li *ngIf="this.authService.loggedIn()" class="gla">
            <a routerLink='rsvp-data'>{{ 'myData' | transloco }}</a>
          </li>

          <li *ngIf="this.authService.loggedIn()" class="gla">
            <a class="login" routerLink="home" (click)="logOut()"><p>{{ 'logout' | transloco }}, {{ this.user.name }}</p></a>
          </li>
          
          <li *ngIf="!this.authService.loggedIn()" class="gla">
            <a class="login" routerLink="rsvp">{{ 'login' | transloco }}</a>
          </li>

          <li>
            <a class="button" role="button" (click)="changeSiteLanguage()" data-toggle="dropdown" > {{ this.languageService.getSiteLanguage() }}</a>
          </li>
        </ul>
      </div>
      <!-- Top Menu End -->
    </div>
    <!-- container end -->
  </nav>

</header>
<!-- Header End -->