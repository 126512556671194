<div class="modal" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{ title }}</h2>
            </div>
            <form *ngIf="!isEdit" (ngSubmit)="confirmationOK()">
                <div class="modal-body">
                    <p>
                        <label class="form-label">Nombre:</label>
                        <input type="text" [(ngModel)]="createFamilyRequest.name" class="form-control form-opacity" placeholder="Nombre" />
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-secondary" type="submit">{{ submitButton }}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>

            <form *ngIf="isEdit" (ngSubmit)="confirmationOK()">
                <div class="modal-body">
                    <p>
                        <label class="form-label">Nombre de la família:</label> 
                        <input type="text" [(ngModel)]="updateFamilyRequest.name" class="form-control form-opacity" placeholder="Nombre" name="updateGuestRequest.name"/>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-secondary" type="submit">{{ submitButton }}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>
