import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { catchError, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { SearchGuestRequest } from 'src/app/models/guest/guest.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/auth/user.service';
import { SearchGuestResponse, SearchService } from 'src/app/services/search/search.service';
import { ModalService } from 'src/app/services/shared/modal.service';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})

export class RsvpComponent implements OnInit {
  foundGuests: SearchGuestResponse[] = null;
  searchGuest: SearchGuestRequest = null;
  @ViewChild('#input') inputField: ElementRef<any>;
  searchTimeout;

  constructor(public authService: AuthService, private _userService: UserService, private router: Router, 
    private _searchService: SearchService, public modalService: ModalService, private translocoService: TranslocoService) {}
  
  ngOnInit() {
    this.searchGuest = { searchname: '', searchfirstsurname: '', searchlastsurname: '', searchCode: this.authService.getValidCode() };
  }

  changed(event) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (this.searchGuest.searchname.length >= 3 && this.searchGuest.searchfirstsurname.length >= 3) {
        this.submitSearch();
      }
    }, 500);
  }
  

  get(guest: SearchGuestResponse){
    let sureGuest = this.translocoService.translate('sureGuest');
    let login = this.translocoService.translate('login');
    this.modalService.open(login, sureGuest + " " + guest.name + " " + guest.firstSurName + " " + guest.lastSurName + "?", false).onClose().pipe(take(1),share()).subscribe(value => {
      if(value.responseButton == 1){
        this.submitLogin(guest.id);
      }
    });
  }

  loginUser() {
      localStorage.setItem('token', 'secretToken');
      this.router.navigate(['/p/home']);
  }

  clear(){
    this.foundGuests = null;
    this.searchGuest = { searchname: '', searchfirstsurname: '', searchlastsurname: '', searchCode: this.authService.getValidCode() };
  }

  submitSearch() {
    this._searchService.searchGuest(this.searchGuest).pipe(take(1)).subscribe(data => {
      this.foundGuests = data;
    });
  }

  submitLogin(guestId: string) {
    this.authService.getGuestToken(guestId).pipe(
      shareReplay(1),
      take(1),
      switchMap(token => {
        this.authService.setToken(token);
        return this._userService.getMe().pipe(
        shareReplay(1),
          catchError(userError => {
            console.error('Error al obtener el usuario:', userError);
            return of(null);
          })
        );
      })
    ).subscribe(
      user => {
        this.router.navigate(['/p/rsvp-data']);
      },
      error => {
        console.error('Error al obtener el token:', error);
      }
    );
  }
}


