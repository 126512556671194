import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponse } from 'src/app/models/modal/modal.interface';

@Component({
  selector: 'app-put-feed-info-guest',
  templateUrl: './put-feed-info-guest.component.html',
  styleUrls: ['./put-feed-info-guest.component.scss']
})
export class PutFeedInfoGuestComponent implements OnInit, OnDestroy {
  modalResponse: ModalResponse = { responseButton: 3, response: null}
  onClose:Subject<ModalResponse> = new Subject<ModalResponse>();
  isOpen = false;
  isInput = false;
  submitButton: string = "Guardar";
  public feedInfo: boolean[];


  private element: any;
  public title: string;
  public body: string;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
      this.close();
      document.body.appendChild(this.element);
      this.element.addEventListener('click', (el: any) => {
          if (el.target.className === 'modal') {
              this.close();
          }
      });
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    this.isOpen = false;
  }

  setCeliac(isTrue: boolean){
    this.feedInfo[0] = isTrue;
  }

  setVegetarian(isTrue: boolean){
    this.feedInfo[1] = isTrue;
  }
  
  setVegan(isTrue: boolean){
    this.feedInfo[2] = isTrue;
  }

  setAllergic(isTrue: boolean){
    this.feedInfo[3] = isTrue;
  }

  setData(title: string, body: string, feedInfo: boolean[]){
    this.title = title;
    this.body = body;
    this.feedInfo = feedInfo;
  }

  open(title: string, body: string, feedInfo: boolean[],  isInput: boolean, firstButton?: string) {
    this.setData(title, body, feedInfo);

    if(firstButton != null){
        this.submitButton = firstButton;
    }
    
    if(isInput){
        this.isInput = true;
    }else{
        this.isInput = false;
    }

    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    this.isOpen = true;

    return {
        onClose:()=>this.onClose
    }
  }

  confirmationOK(){
    this.modalResponse.responseButton = 1
      if(this.isInput){
        this.modalResponse.response = this.feedInfo;
      }
      this.setOnClose();
  }

  confirmationNOK(){
    this.modalResponse.responseButton = 2;
    this.setOnClose();
  }

  setOnClose(){
      this.close();
      this.onClose.next(this.modalResponse);
  }

  ngOnDestroy() {
  }
}
