<h1>Posts</h1>
<div class="row">
  <div class="col-12">
    <textarea type="text" [(ngModel)]="this.content" name="content" class="form-control form-opacity" placeholder="Usuario"></textarea>
    <span [innerHTML]="this.content | markdown"></span>
  </div>
</div>
<!-- post-list.component.html -->
<mat-card>
  <mat-card-title>List of Posts</mat-card-title>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let post of postsPage?.posts">
        <h3 matLine>{{ post.id }}</h3>
        <p matLine>{{ post.alias }}</p>
        <div mat-list-avatar></div>
        <div mat-list-avatar>
          <button mat-icon-button (click)="editPost(post.id)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <div mat-list-avatar>
          <button mat-icon-button (click)="deletePost(post.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

