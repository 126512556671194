import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Inject } from '@angular/core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, share, take } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { CodeService } from 'src/app/services/auth/code.service';
import { LanguageService } from 'src/app/services/shared/language.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { ModalService } from 'src/app/services/shared/modal.service';

@Component({
  selector: 'public-app-root',
  templateUrl: './public-app.component.html',
  styleUrls: ['./public-app.component.scss'],
})
export class PublicAppComponent implements OnInit {
  title = 'Salva & Teresa 2024';
  public isPassedIn = false;
  private codeServiceSubscription: Subscription | undefined;


  constructor(public router: Router, private _http: HttpClient, public appComponent: AppComponent, public modalService: ModalService, public codeService: CodeService, 
    public languageService: LanguageService, public loadingService: LoadingService, private cdRef: ChangeDetectorRef, private _translocoService: TranslocoService) {}

  ngOnInit() {
    
    this.appComponent.isAdminMode = false;

    this.codeServiceSubscription = this.codeService.currentIsPassedIn.subscribe(
      currentIsPassedIn => {
        this.isPassedIn = currentIsPassedIn;
      }
    );
    this.codeService.checkValidCode();
  }
}
