import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { User } from "src/app/models/auth/user.interface";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root',
  })
  export class UserService {
    private _environment;
    public user: User;
    private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject({} as User);
    public readonly currentUser: Observable<User> = this.currentUserSubject.asObservable();
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment, private _authService: AuthService) {
      this._environment = environment
    }

    getMe(): Observable<User> {
      return this.httpClient
        .get<User>(this._environment.apiUrl + '/users/me')
        .pipe(
          retry(1),
          tap(
            (userData: User) => {
              this.handleSuccess(userData);
            },
            (error) => console.error('HTTP Request Error:', error)
          ),
          catchError((error) => {
            return this.processError(error);
          })
        );
    }

    private handleSuccess(userData: User) {
      this.user = userData;
      this.setCurrentUser(userData);
    }
    
    setCurrentUser(currentUser: User): void {
      if(currentUser != null){
        this.currentUserSubject.next(currentUser);
      }
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }