import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'admin-app-root',
  templateUrl: './admin-app.component.html',
  styleUrls: ['./admin-app.component.scss'],
})
export class AdminAppComponent implements OnInit {
  title = 'admin-panel-layout';
  sideBarOpen = true;

  constructor(public appComponent: AppComponent, public authService: AuthService) {}

  ngOnInit() {
    this.appComponent.isAdminMode = true;
  }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
