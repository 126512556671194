<div class="modal" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{ title }}</h2>
            </div>
            <form *ngIf="!isEdit" (ngSubmit)="confirmationOK()">
                <div class="modal-body">
                    <p>
                        <label class="form-label">Nombre:</label>
                        <input type="text" [(ngModel)]="createGuestRequest.name" class="form-control form-opacity" placeholder="Nombre" />
                    </p>
                    <p>
                        <label class="form-label">Primer Apellido:</label>
                        <input type="text" [(ngModel)]="createGuestRequest.firstSurName" class="form-control form-opacity" placeholder="Primer Apellido" />
                    </p>
                    <p>
                        <label class="form-label">Segundo Apellido:</label>
                        <input type="text" [(ngModel)]="createGuestRequest.lastSurName" class="form-control form-opacity" placeholder="Segundo Apellido" />
                    </p>
                    <p>
                        <label class="form-label">Tipo de Invitado:</label>
                        <select [(ngModel)]="createGuestRequest.type" class="form-select form-opacity">
                            <option [value]="0">Adulto</option>
                            <option [value]="1">Niño</option>
                            <option [value]="2">Bebé</option>
                        </select>
                    </p>
                    <p>
                        <label class="form-check-label">
                            <input type="checkbox" [(ngModel)]="createGuestRequest.isParent" class="form-check-input" /> ¿Es padre?
                        </label>
                    </p>
                    <p>
                        <label class="form-check-label">
                            <input type="checkbox" [(ngModel)]="createGuestRequest.isMale" class="form-check-input" /> ¿Es hombre?
                        </label>
                    </p>
                    <p>
                        <label class="form-check-label">
                            <input type="checkbox" [(ngModel)]="createGuestRequest.canAddCompanion" class="form-check-input" /> ¿Puede agregar acompañante?
                        </label>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-secondary" type="submit">{{ submitButton }}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>

            <form *ngIf="isEdit" (ngSubmit)="confirmationOK()">
                <div class="modal-body">
                    <p>
                        <label class="form-label">Nombre:</label> 
                        <input type="text" [(ngModel)]="updateGuestRequest.name" class="form-control form-opacity" placeholder="Nombre" name="updateGuestRequest.name"/>
                    </p>
                    <p>
                        <label class="form-label">Primer Apellido:</label>
                        <input type="text" [(ngModel)]="updateGuestRequest.firstSurName" class="form-control form-opacity" placeholder="Primer Apellido" name="updateGuestRequest.firstSurName"/>
                    </p>
                    <p>
                        <label class="form-label">Segundo Apellido:</label>
                        <input type="text" [(ngModel)]="updateGuestRequest.lastSurName" class="form-control form-opacity" placeholder="Segundo Apellido" name="updateGuestRequest.lastSurName"/>
                    </p>
                    <p>
                        <label class="form-label">Tipo de Invitado:</label>
                        <select [(ngModel)]="updateGuestRequest.type" class="form-select form-opacity">
                            <option [value]="0">Adulto</option>
                            <option [value]="1">Niño</option>
                            <option [value]="2">Bebé</option>
                        </select>
                    </p>
                    <p>
                        <label class="form-check-label">
                            <input type="checkbox" [(ngModel)]="updateGuestRequest.isParent" class="form-check-input" name="updateGuestRequest.isParent"/> ¿Es padre?
                        </label>                        
                    </p>
                    <p>
                        <label class="form-check-label">
                            <input type="checkbox" [(ngModel)]="updateGuestRequest.isMale" class="form-check-input" name="updateGuestRequest.isMale"/> ¿Es hombre?
                        </label>
                    </p>
                    <p>
                        <label class="form-check-label">
                            <input type="checkbox" [(ngModel)]="updateGuestRequest.canAddCompanion" class="form-check-input" name="updateGuestRequest.canAddCompanion"/> ¿Puede agregar acompañante?
                        </label>
                    </p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-secondary" type="submit">{{ submitButton }}</button>
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>

