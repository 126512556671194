import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/shared/loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewChecked, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(public loadingService: LoadingService, private cdRef: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    this.loadingService.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
