<app-add-edit-family #addEditFamilyComponent></app-add-edit-family>
<app-add-edit-guest #addEditGuestComponent></app-add-edit-guest>
<app-put-attendance-guest #putAttendanceGuestComponent></app-put-attendance-guest>
<app-put-feed-info-guest #putFeedInfoGuestComponent></app-put-feed-info-guest>
<app-put-feed-info-comments-guest #putFeedInfoCommentsGuestComponent></app-put-feed-info-comments-guest>
<app-put-bus-info #putBusInfoGuestComponent></app-put-bus-info>

<h1>Invitados</h1>
<div class="filter-container">
  <div class="filters">
    <button mat-raised-button (click)="addFamily()" style="background-color: #fff;">Añadir familia</button>
    <button mat-raised-button (click)="getFilteredFamilies(null, null)" style="background-color: #fff;">Todos</button>
    <button mat-raised-button (click)="getFilteredFamilies(0, null)" style="background-color: #fff;">Pendientes</button>
    <button mat-raised-button (click)="getFilteredFamilies(2, null)" style="background-color: #fff;">No Asistirán</button>
    <button mat-raised-button (click)="getFilteredFamilies(1, null)" style="background-color: #fff;">Asistirán</button>
  </div>

  <div class="search-container">
    <input matInput placeholder="Busca invitados" color="primary" class="px-2" style="width: 300px; background-color: white;" [(ngModel)]="searchWordFilter">
    <button class="btn" (click)="currentPage=0;getFamilies()">
      <mat-icon style="padding: 5px; font-size: 20px;">search</mat-icon>
    </button>
  </div>
</div>
<mat-table [dataSource]="dataSource" class="bg-white border shadow">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Familias</mat-header-cell>
    <mat-cell *matCellDef="let family">
      {{ family.name }}
      <button class="btn text-secondary" (click)="updateFamily(family.id)">
        <mat-icon style="padding: 5px; font-size: 20px;">edit</mat-icon>
      </button>
      <button class="btn text-danger" (click)="deleteFamily(family.id)">
        <mat-icon style="padding: 5px; font-size: 20px;">delete</mat-icon>
      </button>
      <button class="btn" (click)="addGuest(family.id)">
        <mat-icon style="padding: 5px; font-size: 20px;">add</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="guests" class="row">
    <mat-header-cell *matHeaderCellDef>Invitados</mat-header-cell>
    <mat-cell *matCellDef="let family">
      <mat-table [dataSource]="family.guests">

        <ng-container matColumnDef="guestName">
          <mat-cell *matCellDef="let guest" class="col-md-5">
            <mat-icon *ngIf="guest.type === 1" style="color: orange; font-size: 20px;">child_care</mat-icon> 
            <mat-icon *ngIf="guest.type === 2" style="color: orange; font-size: 20px;">crib</mat-icon>
            <span class="{{ guest.type === 2 ? 'text-secondary' : '' }}">
              {{ guest.name }} {{ guest.firstSurName }} {{ guest.lastSurName }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="infoGuest">
          <mat-cell *matCellDef="let guest" class="col-md-5">
            <button class="btn px-2" (click)="putAttendance(guest.id, guest.attendance)">
              <mat-icon *ngIf="guest.attendance === 1" style="color: green; padding: 5px; font-size: 20px;">done</mat-icon>
              <mat-icon *ngIf="guest.attendance === 2" style="color:red; padding: 5px; font-size: 20px;">close</mat-icon>
              <mat-icon *ngIf="guest.attendance === 0" style="color: orange; padding: 5px; font-size: 20px;">schedule</mat-icon>
            </button>
            <button class="btn px-2" (click)="putBusInfo(guest.id)">
              <mat-icon *ngIf="guest.busInfo.isNeeded" style="color: black;">commute</mat-icon>
              <mat-icon *ngIf="!guest.busInfo.isNeeded" style="color: lightgray;">commute</mat-icon>
            </button>
            <ng-container *ngIf="guest.busInfo.isNeeded">
              <ng-container *ngFor="let trip of guest.busInfo.trips">
                <mat-icon *ngIf="!trip.isNeeded && trip.type === 0" style="color: lightgray;"
                          [matTooltip]="'IDA: Ciudad: ' + (trip.busInfoCity ? trip.busInfoCity.name : '') +
                                      ', Hora: ' + (trip.busInfoTime ? trip.busInfoTime.time : '')">
                    arrow_upward
                </mat-icon>
                <mat-icon *ngIf="trip.isNeeded && trip.type === 0" style="color: orange;"
                          [matTooltip]="'IDA: Ciudad: ' + (trip.busInfoCity ? trip.busInfoCity.name : '') +
                                      ', Hora: ' + (trip.busInfoTime ? trip.busInfoTime.time : '')">
                    arrow_upward
                </mat-icon>
                <mat-icon *ngIf="!trip.isNeeded && trip.type === 1" style="color: lightgray;"
                          [matTooltip]="'VUELTA: Ciudad: ' + (trip.busInfoCity ? trip.busInfoCity.name : '') +
                                      ', Hora: ' + (trip.busInfoTime ? trip.busInfoTime.time : '')">
                    arrow_downward
                </mat-icon>
                <mat-icon *ngIf="trip.isNeeded && trip.type === 1" style="color: orange;"
                          [matTooltip]="'VUELTA: Ciudad: ' + (trip.busInfoCity ? trip.busInfoCity.name : '') +
                                      ', Hora: ' + (trip.busInfoTime ? trip.busInfoTime.time : '')">
                    arrow_downward
                </mat-icon>
            </ng-container>
            
            </ng-container>
            <button class="btn px-2" [matTooltip]="'Hay que tener precaución al servir su menú'" (click)="putFeedInfo(guest.id, [guest.feedInfo.isCeliac, guest.feedInfo.isVegetarian, guest.feedInfo.isVegan, guest.feedInfo.isAllergic])">
              <mat-icon *ngIf="guest.feedInfo.isCeliac || guest.feedInfo.isVegetarian || guest.feedInfo.isVegan || guest.feedInfo.isAllergic" style="color: orange; padding: 5px; font-size: 20px;">report_problem</mat-icon>
              <mat-icon *ngIf="!(guest.feedInfo.isCeliac || guest.feedInfo.isVegetarian || guest.feedInfo.isVegan || guest.feedInfo.isAllergic)" style="color: lightgray; padding: 5px; font-size: 20px;">restaurant_menu</mat-icon>
            </button>
            <button class="btn px-2">
              <mat-badge *ngIf="guest.feedInfo.isCeliac" [matBadge]="true" matBadgeOverlap="false" class="px-2" matTooltip="Celíaco">C</mat-badge>
              <mat-badge *ngIf="guest.feedInfo.isVegetarian" [matBadge]="true" matBadgeOverlap="false" class="px-2" matTooltip="Vegetariano">Vt</mat-badge>
              <mat-badge *ngIf="guest.feedInfo.isVegan" [matBadge]="true" matBadgeOverlap="false" class="px-2" matTooltip="Vegano">V</mat-badge>
              <mat-badge *ngIf="guest.feedInfo.isAllergic" [matBadge]="true" matBadgeOverlap="false" class="px-2" matTooltip="Alérgico">A</mat-badge>
            </button>
            <button class="btn px-2" (click)="putFeedInfoComments(guest.id, guest.feedInfo.comments)" [matTooltip]="guest.feedInfo.comments">
              <mat-icon *ngIf="guest.feedInfo.comments" style="padding: 5px; font-size: 20px;">feedback</mat-icon>
              <mat-icon *ngIf="!guest.feedInfo.comments" style="color: lightgray; padding: 5px; font-size: 20px;">feedback</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="editGuest">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let guest" class="col-md-2">
            <button class="btn text-secondary" (click)="updateGuest(guest.id)">
              <mat-icon style="padding: 5px; font-size: 20px;">edit</mat-icon>
            </button>
            <button class="btn text-danger" (click)="deleteGuest(guest.id)">
              <mat-icon style="padding: 5px; font-size: 20px;">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let guest; columns: ['guestName', 'infoGuest','editGuest']"></mat-row>
      </mat-table>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['name', 'guests']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['name', 'guests']"></mat-row>
</mat-table>

<mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>

