<div class="modal" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{title}}</h2>
            </div>
            <form (ngSubmit) = "confirmationOK()" >
                <div class="text-center">
                    <p>{{body}}</p>
                    <button class="{{ this.attendanceType === 0 ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setAttendance(0)">Pendiente</button>
                    <button class="{{ this.attendanceType === 1 ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setAttendance(1)">Asistirá</button>
                    <button class="{{ this.attendanceType === 2 ? 'btn-primary' : 'btn-secondary' }}" class="btn btn-sm mx-1" (click)="setAttendance(2)">No asistirá</button>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmationNOK()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-backdrop show"></div>