import { Component, OnInit } from '@angular/core';
import { PostPageResponse } from 'src/app/models/post/post.interface';
import { AdminPostService } from 'src/app/services/admin/posts/admin-post.service';

@Component({
  selector: 'admin-app-posts',
  templateUrl: './admin-posts.component.html',
  styleUrls: ['./admin-posts.component.scss']
})
export class AdminPostsComponent implements OnInit {
  public content: string;
  postsPage: PostPageResponse;

  constructor(private adminPostService: AdminPostService) { }

  ngOnInit() {
    this.getPosts();
  }

  getPosts() {
    this.adminPostService.getPosts().subscribe(response => {
      this.postsPage = response;
    }, error => {
      console.error('Error al obtener la lista de posts:', error);
    });
  }
}
