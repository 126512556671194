<h1>Mensajes</h1>
<div class="card shadow-sm p-3 mb-5 bg-white">
  <ng-container *ngFor="let family of groupedMessages | keyvalue">
    <h2 class="text-center">{{ family.key }}</h2>
      <mat-list>
        <mat-list-item *ngFor="let message of family.value">
          <p class="text-center text-secondary">{{ message.body }}</p>
        </mat-list-item>
      </mat-list>
  </ng-container>
  <mat-paginator [length]="total" [pageSize]="pageSize" [pageIndex]="pageNumber"
               [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)">
  </mat-paginator>
</div>


