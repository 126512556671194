import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class LanguageService {

    constructor(private translocoService: TranslocoService) { }

    private siteLanguage = 'val';
    private languageList = [
      { code: 'val', label: 'val' },
      { code: 'cas', label: 'cas' },
    ];
  
    changeSiteLanguage() {
        let activeLang = this.translocoService.getActiveLang();
        let language = this.languageList.find(f => f.code !== activeLang).label;
        this.siteLanguage = language;
        this.translocoService.setActiveLang(this.siteLanguage);
        this.setSiteLanguage();
    }

    getSiteLanguage() {
        return this.siteLanguage;
    }

    getUserSiteLanguage() {
        return localStorage.getItem('language');
    }

    getLanguageList() {
        return this.languageList;
    }

    setSiteLanguage(){
        localStorage.setItem('language', this.translocoService.getActiveLang());
    }

    setFirstSiteLanguage(firstLanguage: string){
        this.translocoService.setActiveLang(firstLanguage);
        this.setSiteLanguage();
    }

    updateSiteLanguage(){
        if(localStorage.getItem('language')){
            this.translocoService.setActiveLang(localStorage.getItem('language'));
        }
    }
}