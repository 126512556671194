<ng-container *ngIf="!this.appComponent.isAdminMode">
    <app-top-nav *ngIf="this.isPassedIn"></app-top-nav>
    <div class="gla_page" id="gla_page">
        <section id="gla_content" class="gla_content">
            <router-outlet></router-outlet>
        </section>
    </div>
    <app-footer *ngIf="this.isPassedIn"></app-footer>
    <app-loader></app-loader>
    <!--<div class="gla_music_icon bg-black rounded" *ngIf="this.isPassedIn">
        <app-audio-player></app-audio-player>
    </div>-->
    <!--<a href="#gla_page" class="gla_go text-secondary ti ti-angle-up">PRUEBA</a>-->
</ng-container>
<app-modal #modal></app-modal>
