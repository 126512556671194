import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable()
export class CodeService {
  private _environment;
  public isPassedIn = false;
  private currentIsPassedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public readonly currentIsPassedIn: Observable<boolean> = this.currentIsPassedInSubject.asObservable();

  constructor(private httpClient: HttpClient, @Inject('environment') environment) { 
    this._environment = environment;
  }

  async checkCode(code: string): Promise<boolean> {
    try {
      const response: HttpResponse<any> = await this.httpClient.post(
        this._environment.apiUrl + '/auth/code/' + code,
        null,
        { observe: 'response' }
      ).toPromise();

      if (response && response.status === 200) {
        this.setValidCode(code);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error in checkCode:', error);
      return false;
    }
  }

  getValidCode(){
    return localStorage.getItem('validcode');
  }

  setCode(value: string){
    return localStorage.setItem('code', value);
  }

  checkValidCode(){
    var validCode = this.getValidCode();
    if(validCode != null){
      this.checkCode(validCode);
    }
  }

  setValidCode(value: string){
    this.isPassedIn = true;
    this.setCurrentPassedIn(true);
    return localStorage.setItem('validcode', value);
  }

  setCurrentPassedIn(currentIsPassedIn: boolean): void {
    if(currentIsPassedIn != null){
      this.currentIsPassedInSubject.next(currentIsPassedIn);
    }
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    console.log(message);
    return throwError(() => {
      message;
    });
  }
}