import { AfterViewInit, Component, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { take } from 'rxjs';
import { FamilySongResponse } from 'src/app/models/song/song.interface';
import { AdminSongsService } from 'src/app/services/admin/songs/admin-songs.service';

@Component({
  selector: 'app-admin-guests-songs',
  templateUrl: './guests-songs.component.html',
  styleUrls: ['./guests-songs.component.scss']
})
export class AdminGuestsSongsComponent implements AfterViewInit {
  @Input() songs: FamilySongResponse[];
  @Input() total: number = 0;
  pageSize: number = 10;
  pageNumber: number = 1;
  
  constructor(private _adminSongsService: AdminSongsService) {
  }

  ngAfterViewInit(): void {
    this.getSongs();
  }

  getSongs(){
    this._adminSongsService.getAllSongs(this.pageSize, this.pageNumber).pipe(take(1)).subscribe(data => {
      this.songs = data.songs;
      this.total = data.total;
    });
  }

  onPageChange(event: PageEvent) {
    if(event.pageIndex > 0){
      this.pageNumber = event.pageIndex;
      this.getSongs();
    }
  }
}
