<div class="container-fluid">
    <div class="row d-flex align-items-center justify-content-center vh-100">
        <div class="col-12 col-md-6 text-center">
            <img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt="">
            <h1 style="margin-bottom: 10px; margin-top: 50px;"> {{ 'code-label' | transloco }}</h1>
            <div class="m-4">
                <span *ngIf="this.codeErrorMessage !=''" class="alert alert-danger center m-2" role="alert">
                    {{ this.codeErrorMessage }}
                </span>
                <input type="text" [(ngModel)]="this.code" name="inputText" class="form-control form-opacity" placeholder="{{ 'code-placeholder' | transloco }}"/>
                <button class="btn btn-primary" target="_blank" role="button" (click)="submitCode(code)" [disabled]="this.code == ''">Enviar</button>
            </div>
        </div>
    </div>
</div>
