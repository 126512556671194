<mat-drawer-container *ngIf="this.appComponent.isAdminMode && this.authService.isAdmin()">
  <mat-drawer mode="side" [opened]="sideBarOpen">
    <admin-app-sidenav></admin-app-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <admin-app-header (toggleSidebarForMe)="sideBarToggler()"></admin-app-header>
    <div style="height: 90vh; display: flex; flex-direction: column; align-items: center; justify-content: flex-start;">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<admin-app-modal #modal></admin-app-modal>

<admin-app-login *ngIf="this.appComponent.isAdminMode && !this.authService.isAdmin()"></admin-app-login>

