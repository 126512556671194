import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AttendanceType } from 'src/app/models/guest/guest.interface';
import { ModalResponse } from 'src/app/models/modal/modal.interface';

@Component({
  selector: 'app-put-attendance-guest',
  templateUrl: './put-attendance-guest.component.html',
  styleUrls: ['./put-attendance-guest.component.scss']
})
export class PutAttendanceGuestComponent implements OnInit, OnDestroy {
  modalResponse: ModalResponse = { responseButton: 3, response: null}
  onClose:Subject<ModalResponse> = new Subject<ModalResponse>();
  isOpen = false;
  isInput = false;
  public attendanceType: AttendanceType = 0;
  submitButton: string = "Guardar";

  private element: any;
  public title: string;
  public body: string;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
      this.close();
      document.body.appendChild(this.element);
      this.element.addEventListener('click', (el: any) => {
          if (el.target.className === 'modal') {
              this.close();
          }
      });
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    this.isOpen = false;
  }

  setAttendance(attendanceType: AttendanceType){
    this.attendanceType = attendanceType;
  }

  setData(title: string, body: string, attendance: AttendanceType){
    this.title = title;
    this.body = body;
    this.attendanceType = attendance;
  }

  open(title: string, body: string, attendance: AttendanceType, isInput: boolean, firstButton?: string) {
    this.setData(title, body, attendance);

    if(firstButton != null){
        this.submitButton = firstButton;
    }
    
    if(isInput){
        this.isInput = true;
    }else{
        this.isInput = false;
    }

    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    this.isOpen = true;

    return {
        onClose:()=>this.onClose
    }
  }

  confirmationOK(){
    this.modalResponse.responseButton = 1
      if(this.isInput){
          this.modalResponse.response = this.attendanceType;
      }
      this.setOnClose();
  }

  confirmationNOK(){
    this.modalResponse.responseButton = 2;
    this.setOnClose();
  }

  setOnClose(){
      this.close();
      this.onClose.next(this.modalResponse);
  }

  ngOnDestroy() {
  }
}
