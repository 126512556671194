<section class="gla_section">
    <div infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="100"
    [fromRoot]="true"
    (scrolled)="onScroll()" class="container text-center">
  <p><img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt=""></p>
  <h1>Galeria</h1>
  <p *ngIf="this.imagesPage.images.length > 0">{{ 'gallery-title-label' | transloco }}</p>

  <!--<div class="button-group filter-button-group">
  <a data-filter="*">Mostrar todas</a>
  <a data-filter=".fun">Diversión</a>
  <a data-filter=".family">Familia & Amigos</a>
  <a data-filter=".milestones">Hitos</a>
  </div> -->
  <!-- filters end -->

  <!-- grid -->
  <div *ngIf="this.imagesPage.images" class="row" style="position: relative;">
  <ng-container *ngFor="let image of this.gallery">
    <div class="col-6 col-sm-3">
        <div class="gla_shop_item">
          <a (click)="setImageSelected(image.data)">
            <img src="{{image.data}}" id="some-id">
          </a>
        </div>
    </div>
  </ng-container>
  </div>
  <div class="col-md-8 col-md-push-2" *ngIf="!(this.imagesPage.images.length > 0)">
    <div class="mt-4 py-2 text-center">
      <span class="fs-2">{{ 'not-found-images-label' | transloco }} </span>
    </div>
  </div>
  <!-- grid end -->
  <!-- Slide Down -->
  <a *ngIf="this.leftMoreImages && this.imagesPage.images.length > 0" class="gla_scroll_down_dark gla_go my-5 py-5">
  <b></b>
  <!--{{'scroll-label' | transloco }}-->
  </a>
  </div>
  <!-- container end -->
</section>

