import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { DashboardAttendanceGuestResponse, DashboardBusInfoResponse, DashboardFeedInfoGuestResponse } from "src/app/models/admin/dashboard/dashboard.interface";

@Injectable({
    providedIn: 'root',
  })
  export class DashboardService {
    private _environment;
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment) {
      this._environment = environment
    }
    
   getDashboardAttendanceGuests(): Observable<DashboardAttendanceGuestResponse> {
      return this.httpClient
        .get<DashboardAttendanceGuestResponse>(
          this._environment.apiUrl + '/dashboard/attendances'
        )
        .pipe(retry(1), catchError(this.processError));
    }

    getDashboardFeedInfoGuests(): Observable<DashboardFeedInfoGuestResponse> {
      return this.httpClient
        .get<DashboardFeedInfoGuestResponse>(
          this._environment.apiUrl + '/dashboard/feed-infos'
        )
        .pipe(retry(1), catchError(this.processError));
    }

    getDashboardBusInfo(): Observable<DashboardBusInfoResponse> {
      return this.httpClient
        .get<DashboardBusInfoResponse>(
          this._environment.apiUrl + '/dashboard/bus-infos'
        )
        .pipe(retry(1), catchError(this.processError));
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }