import { AttendanceType, GuestType } from "../../guest/guest.interface";

export interface KeyValuePair<T, U> {
  key: T;
  value: U;
}
  
export interface DashboardGuestAttendanceResponse {
  attendance: KeyValuePair<number, number>[];
  type: AttendanceType;
}

export interface DashboardAttendanceGuestResponse {
  guestAttendances: DashboardGuestAttendanceResponse[] | null;
}

export interface DashboardBusInfoResponse {
  trips: DashboardBusInfoTripResponse[] | null;
}

export interface DashboardBusInfoTripResponse {
  type: BusTripType;
  citiesTimes:  DashboardBusInfoCitiesTimesResponse[] | null;
}

export interface DashboardBusInfoCitiesTimesResponse {
  city: string;
  timesGuestsCount: DashboardBusInfoTimeGuestCountResponse[] | null;
}

export interface DashboardBusInfoTimeGuestCountResponse {
  time: Date;
  count: number;
}

export interface DashboardFeedInfoGuestResponse {
  adults: DashboardGuestFeedInfoResponse | null;
  kids: DashboardGuestFeedInfoResponse | null;
}

export interface DashboardGuestFeedInfoResponse {
  celiacs: number;
  vegetarians: number;
  vegans: number;
  allergics: number;
}

export enum BusTripType {
  Ida = 0,
  Vuelta = 1
}