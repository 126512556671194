import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { ImagesPageResponse } from "src/app/models/gallery/image.interface";
import { AuthService } from "../auth/auth.service";

@Injectable({
    providedIn: 'root',
  })
  export class GalleryService {
    private _environment;
  
    constructor(private httpClient: HttpClient, private _authService: AuthService, @Inject('environment') environment) {
      this._environment = environment
    }
    
   getImages(pageSize: number, pageNumber: number): Observable<ImagesPageResponse> {
      return this.httpClient
        .get<ImagesPageResponse>(
          this._environment.apiUrl + "/gallery/" + this._authService.getValidCode() + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize,
        )
        .pipe(retry(1), catchError(this.processError));
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }