import { AfterViewInit, Component, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { take } from 'rxjs';
import { AdminMessageResponse } from 'src/app/models/admin/messages/admin-messages.interface';
import { AdminMessagesService } from 'src/app/services/admin/messages/admin-messages.service';

@Component({
  selector: 'app-guests-messages',
  templateUrl: './guests-messages.component.html',
  styleUrls: ['./guests-messages.component.scss']
})
export class AdminGuestsMessagesComponent implements AfterViewInit {
  @Input() messages: AdminMessageResponse[] = [];
  @Input() total: number = 0;
  pageSize: number = 10;
  pageNumber: number = 1;
  groupedMessages: { [key: string]: any[] } = {};

  constructor(private _adminMessagesService: AdminMessagesService) {
  }

  ngAfterViewInit(): void {
    this.getMessages();
  }

  getMessages(){
    this._adminMessagesService.getAllMessages(this.pageSize, this.pageNumber).pipe(take(1)).subscribe(data => {
      this.messages = data.messages;
      this.total = data.total;
      this.groupMessagesByFamily();
    });
  }

  groupMessagesByFamily() {
    this.groupedMessages = {};
    this.messages.forEach(message => {
      console.log(JSON.stringify(message));
      const familyName = message.familyName;
      if (!this.groupedMessages[familyName]) {
        this.groupedMessages[familyName] = [];
      }
      this.groupedMessages[familyName].push(message);
    });
    console.log(JSON.stringify(this.groupedMessages));
  }

  onPageChange(event: PageEvent) {
    if(event.pageIndex > 0){
      this.pageNumber = event.pageIndex;
      this.getMessages();
    }
  }
}
