import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { PostTranslationPageResponse } from 'src/app/models/post/post.interface';
import { PostService } from 'src/app/services/posts/post.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {
  public postsTranslationsPage: PostTranslationPageResponse = { total: 0, postTranslations: []};
  public content: string;

  constructor(private _postService: PostService, private _router: Router) {
  }

  ngOnInit() {
    this.getPostTranslations();
  }

  getPostTranslations(){
    this._postService.getPostTranslations().subscribe(data => {
      this.postsTranslationsPage = data;
    });
  }

  goToPostDetail(postId: string) {
    const postTranslation = this.postsTranslationsPage.postTranslations.find(post => post.id === postId);
    if (postTranslation) {
      const navigationExtras: NavigationExtras = {
        state: {
          postTranslation: postTranslation
        }
      };
      this._router.navigate(['/p/posts', postTranslation.id], navigationExtras);
    } else {
      console.error('No se encontró la publicación con el ID:', postId);
    }
  }
}
