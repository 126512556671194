import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { share, take } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/shared/modal.service';
import { LanguageService } from 'src/app/services/shared/language.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, AfterViewInit, OnDestroy {
  private _environment;
  private interval;

  constructor(public authService: AuthService, private _translocoService: TranslocoService, @Inject('environment') environment, private _snackBar: MatSnackBar,
    public modalService: ModalService, public languageService: LanguageService)
  { 
    this._environment = environment;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  ngAfterViewInit(): void {
    this.interval = setInterval(() => this.changeImage(), 2000);
    this.showSnackbarIfRemainingAMonth();
  }

  ngOnInit(){
    $.getScript("assets/js/glanz_script.js");
    if(this.languageService.getUserSiteLanguage() == null){
      this.setActiveLanguage();
    } else {
      let language = this.languageService.getUserSiteLanguage();
      this.languageService.setFirstSiteLanguage(language);
    }
  }

  setActiveLanguage(){
    this.modalService.open("Idioma", "¿Prefieres ver la página en valencià o en castellano?", false, "Continuar en valencià", "Cambiar a castellano").onClose().pipe(take(1),share()).subscribe(value => {
      if(value.responseButton == 1){
        this.languageService.setFirstSiteLanguage('cas');
      } else {
        this.languageService.setFirstSiteLanguage('val');
      }
    });
  }

  private changeImage(): void {
    var dynamicImage = document.getElementById("dynamicImage");
    var currentIndex = parseInt(dynamicImage.getAttribute("data-index"));
    var nextIndex = (currentIndex + 1) % 8;
    var imageList = [
      "assets/images/denia.jpg",
      "assets/images/boda.jpg",
      "assets/images/marroc_hijab.jpg",
      "assets/images/barco-ibiza.jpg",
      "assets/images/ibiza.jpg",
      "assets/images/puerta-hierro.jpg",
      "assets/images/venecia.jpg",
      "assets/images/espaldas.jpg"
    ];

    dynamicImage.setAttribute("src", imageList[nextIndex]);
    dynamicImage.setAttribute("data-index", nextIndex.toString());
  }

  showSnackbarIfRemainingAMonth() {
    const currentDate  = new Date();
    const weddingDate = new Date(this._environment.date);
    const diff = weddingDate.getTime() - currentDate.getTime();
    const remainingDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (remainingDays < 30 && remainingDays > 15) {
      this._translocoService.selectTranslate('ok-button-label').pipe(take(1)).subscribe((msgButton) => {
        this._translocoService.selectTranslate('remaining-less-than-month-label').pipe(take(1)).subscribe((msg) => {
          this._snackBar.open(msg, msgButton, {
          duration: 10000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: ['snackbar','snackbar-warn']
          });
        });
      });
    }
  }
}
