import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponse } from 'src/app/models/modal/modal.interface';
import { ModalService } from 'src/app/services/shared/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id?: string;
    modalResponse: ModalResponse = { responseButton: 3, response: null}
    onClose:Subject<ModalResponse>=new Subject<ModalResponse>();
    isOpen = false;
    isInput = false;
    inputText: string;
    inputPlaceholder: string;
    submitButton: string = "Si";

    private element: any;
    public title: string;
    public body: string

    constructor(private modalService: ModalService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        this.modalService.add(this);
        this.close();

        document.body.appendChild(this.element);

        this.element.addEventListener('click', (el: any) => {
            if (el.target.className === 'modal') {
                this.close();
            }
        });
    }

    setData(title: string, body: string){
      this.title = title;
      this.body = body;
    }

    ngOnDestroy() {
        this.modalService.remove(this);
        this.element.remove();
    }

    open(title: string, body: string, isInput: boolean, inputPlaceholder?: string, firstButton?: string) {
        this.setData(title, body);

        if(firstButton != null){
            this.submitButton = firstButton;
        }
        
        if(isInput){
            this.isInput = true;
            this.inputPlaceholder = inputPlaceholder;
        }else{
            this.isInput = false;
        }

        this.element.style.display = 'block';
        document.body.classList.add('modal-open');
        this.isOpen = true;

        return {
            onClose:()=>this.onClose
        }
    }

    close() {
        this.element.style.display = 'none';
        document.body.classList.remove('modal-open');
        this.isOpen = false;
        this.submitButton = "Si";
    }

    confirmationOK(){
        this.modalResponse.responseButton = 1;
        if(this.isInput){
           this.modalResponse.response = this.inputText;
        }
        this.setOnClose();
    }

    confirmationNOK(){
        this.modalResponse.responseButton = 2;
        this.setOnClose();
    }

    setOnClose(){
        this.close();
        this.onClose.next(this.modalResponse);
    }
}

