<section class="gla_section">
    <div class="container text-center">
        <p><img src="assets/images/fulla-dalt.svg" data-bottom-top="@src:assets/images/fulla-dalt.svg" height="50" alt=""></p>
        <h1>{{ 'posts' | transloco }}</h1>
        
        <div class="tabs">
            <div class="tab-content">
                <div class="tab-pane fade in active" id="airports-tab">
                <!-- boxes -->
                <div class="top-margin-lg row text-left">
                    <!-- item -->
                    <ng-container *ngFor="let postTranslation of postsTranslationsPage.postTranslations;">
                        <div class="col-md-8 offset-md-2 col-sm-6">
                            <span class="gla_news_block">
                                <!--<span class="gla_news_img" [innerHTML]="postTranslation.image | markdown"></span>-->
                                <span class="gla_news_title text-center mt-4" [innerHTML]="postTranslation.title | markdown"></span>
                                <p [innerHTML]="postTranslation.description | markdown"></p>
                                <a class="btn my-data btn-sm text-center" (click)="this.goToPostDetail(postTranslation.id)" role="button">{{ 'more-info-button-label' | transloco }}</a>
                            </span>
                        </div>
                    </ng-container>
                </div>
                <!-- boxes end -->
                </div>
                <!-- tab end -->
            </div>  
        </div>

        <!-- wedshoots section -->
        <app-wedshoots></app-wedshoots>
        <!-- wedshoots section end -->
    </div>
    <!-- container end -->

</section>

