import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { AdminSongsPageResponse } from "src/app/models/song/song.interface";

@Injectable({
    providedIn: 'root',
  })
  export class AdminSongsService {
    private _environment;
  
    constructor(private httpClient: HttpClient, @Inject('environment') environment) {
      this._environment = environment
    }
    
   getAllSongs(pageSize: number, pageNumber: number): Observable<AdminSongsPageResponse> {
    let url = `${this._environment.apiUrl}/family/songs/admin/all?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      return this.httpClient
        .get<AdminSongsPageResponse>(url)
        .pipe(retry(1), catchError(this.processError));
    }
  
    processError(err: any) {
      let message = '';
      if (err.error instanceof ErrorEvent) {
        message = err.error.message;
      } else {
        message = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      console.log(message);
      return throwError(() => {
        message;
      });
    }
  }